import type { NumericDictionary } from '~/libs/utility';

import type {
    AdminDevice,
    AdminDriverRead,
    AdminVehicleEdit,
    AdminVehicleRead,
    Attachment,
    ButtonDriverLoginChangelogChanges,
    Classification,
    CompartmentStatus,
    CompletedActivity,
    Container,
    ContainerStatus,
    ContainerTemperatureStatus,
    Conversation,
    ConversationSummary,
    ConversationUpdate,
    Country,
    Depot,
    DeviceStatus,
    DeviceType,
    DoorStatus,
    Driver,
    DriverActivityShift,
    DriverActivityType,
    DriverChangelog,
    DriverChangelogChanges,
    DriverEvent,
    DriverEventField,
    DriverEventFieldValue,
    DriverEventType,
    DriverHoursStatus,
    DriverSubActivityType,
    InhibitorStatus,
    LastContainerEvent,
    LastTrailerEvent,
    ManualDriverLoginChangelogChanges,
    Message,
    MessageBody,
    MessageDirection,
    NamedTrailerReference,
    NamedVehicleReference,
    ReeferStatus,
    RouteStatus,
    RtdsSchedule,
    RtdsScheduleChangelogChanges,
    RtdsSession,
    SendVehicle,
    SettingsIntegerValue,
    SmartCardDriverLogin,
    SmartCardDriverLoginChangelogChanges,
    SpeedLimit,
    TachoFile,
    TachoFileMetadata,
    TachographDataBlock,
    TemperatureSensorStatus,
    Trailer,
    TrailerBatteryStatus,
    TrailerConnection,
    TrailerEvent,
    TrailerStatus,
    TranslateMessageResponse,
    Vehicle,
    VehicleCategory,
    VehicleChangelogChanges,
    VehicleDriverHoursStatus,
    VehicleSettingsChangelogChanges,
    VehicleSettingsEdit,
    VehicleSettingsRead,
    VehicleStatus,
    VehicleType,
    VehicleTypeChangelogChanges,
    VehicleTypeDimensions,
    ViolationType,
    WorkflowFormDefinition,
} from './generated';

export interface ApiModel {
    init: (_: unknown) => void;
    toJSON: (_?: unknown) => unknown;
}

export type PojoModel<T extends ApiModel> = Omit<T, keyof ApiModel>;

type DeepPojoModelArray<T> = Array<DeepPojoModel<T>>;

type DeepPojoModelObject<T> = {
    [Key in keyof T]: DeepPojoModel<T[Key]>;
};

export type DeepPojoModel<T> = T extends Array<infer U>
    ? DeepPojoModelArray<U>
    : T extends Date
      ? T
      : T extends object
        ? DeepPojoModelObject<Omit<T, keyof ApiModel>>
        : T;

type DeepPartial<T> = T extends object
    ? {
          [P in keyof T]?: DeepPartial<T[P]>;
      }
    : T;

export interface ClassificationAbstraction {
    displayName: string;
    id: number | string;
    key: string;
    rowValues?: (string | undefined)[];
}

export type DerivedClassification = ClassificationAbstraction;

export enum DerivedClassificationType {
    VehicleTypeAxlesCombo = 'vehicle-type-axles-combo',
}

export enum ServerResultStatus {
    ACCEPTED = 'Accepted',
    BADREQUEST = 'BadRequest',
    OK = 'Ok',
    PENDING = 'Pending',
    SERVERERROR = 'ServerError',
}

export enum EditorState {
    CLONE = 'Clone',
    MODIFY = 'Modify',
    NEW = 'New',
}

export enum DriverRole {
    CODRIVER = 'co-driver',
    DRIVER = 'driver',
}

export enum DriverActivityKeys {
    AVAILABLE = 'Available',
    DRIVE = 'Drive',
    REST = 'Rest',
    WORK = 'Work',
}

export enum FileTypeKeys {
    BIN = 'Bin',
    CSV = 'Csv',
    DOC = 'Doc',
    GIF = 'Gif',
    JPG = 'Jpg',
    NOTSUPPORTED = 'NotSupported',
    PDF = 'Pdf',
    PNG = 'Png',
    RTF = 'Rtf',
    TIF = 'Tif',
    UNKNOWN = 'Unknown',
    XLS = 'Xls',
    XML = 'Xml',
    ZIP = 'Zip',
}

export enum CompartmentStatusKeys {
    COOLING = 'Cooling',
    DEFROST = 'Defrost',
    HEATING = 'Heating',
    MANUFACTURERSPECIFIC = 'ManufacturerSpecific',
    POWEROFF = 'PowerOff',
    PRETRIP = 'PreTrip',
    SHUTDOWN = 'Shutdown',
    UNKNOWN = 'Unknown',
}

export enum InhibitorStatusKeys {
    DISABLED = 'Disabled',
    DISABLEDBYIBUTTON = 'DisabledByIButton',
    DISABLEDBYPIN = 'DisabledbyPin',
    ENABLED = 'Enabled',
    ENABLEDBYIBUTTON = 'EnabledByIButton',
    UNKNOWN = 'Unknown',
}

export interface ViolationField<T> {
    threshold: T;
    value: T;
    violation?: T;
    violationType: ViolationType;
}

export enum TrailerDoorStatusKeys {
    CLOSED = 'Closed',
    LOCKED = 'Locked',
    OPEN = 'Open',
    SEALED = 'Sealed',
}

export enum ReeferStatusKeys {
    NORMAL = 'NormalOperation',
    OFF = 'Off',
    SHUTDOWN = 'Shutdown',
    WARNING = 'Warning',
}

export enum ReeferPowerModeKeys {
    DIESEL = 'Diesel',
    ELECTRIC = 'Electric',
}

export enum ContainerTemperatureStatusKeys {
    AMBIENT = 'Ambient',
    CONTENT = 'Content',
    HEATER = 'Heater',
    SETPOINT = 'Setpoint',
    UNKNOWN = 'Unknown',
}

export enum RouteStatusKeys {
    ARRIVEDATDESTINATION = 'ArrivedAtDestination',
    BACKINCORRIDOR = 'BackInCorridor',
    NOTNAVIGATING = 'NotNavigating',
    OUTOFCORRIDOR = 'OutOfCorridor',
    STARTNAVIGATING = 'StartNavigating',
}

export const VehicleTypeCategories = {
    CAR: 'Car',
    TRUCK: 'Truck',
    VAN: 'Van',
} as const;

type OmitFromGenerated<T extends ApiModel, K extends keyof T> = Omit<PojoModel<T>, K>;

export type Asset = Driver | Trailer | Vehicle;

export type ResolvedAsset = ResolvedDriver | ResolvedTrailer | ResolvedVehicle;

export interface ResolvedTrailer extends OmitFromGenerated<Trailer, 'reeferManufacturer' | 'trailerManufacturer'> {
    reeferManufacturer?: Classification;
    trailerManufacturer: Classification;
}

export interface ResolvedReeferStatus
    extends OmitFromGenerated<ReeferStatus, 'alarms' | 'operationMode' | 'powerMode' | 'speed' | 'status'> {
    alarms?: Classification[];
    operationMode?: Classification;
    powerMode?: Classification;
    speed?: Classification;
    status?: Classification;
}

export interface ResolvedLastTrailerEvent extends OmitFromGenerated<LastTrailerEvent, 'trailerEventType'> {
    trailerEventType: Classification;
}

export interface ResolvedTrailerBatteryStatus extends OmitFromGenerated<TrailerBatteryStatus, 'status'> {
    status?: Classification;
}

export interface ResolvedDoorStatus extends OmitFromGenerated<DoorStatus, 'status'> {
    status: Classification[];
}

export interface ResolvedCompartmentStatus
    extends OmitFromGenerated<CompartmentStatus, 'status' | 'temperatureSensors'> {
    status?: Classification;
    temperatureSensors: NumericDictionary<TemperatureSensorStatus>;
}

export interface ResolvedInhibitorStatus extends OmitFromGenerated<InhibitorStatus, 'status'> {
    inhibitorStatus: Classification;
}

export interface ResolvedTrailerStatus
    extends OmitFromGenerated<TrailerStatus, 'battery' | 'compartments' | 'doors' | 'hooked' | 'lastEvent' | 'reefer'> {
    battery?: ResolvedTrailerBatteryStatus;
    compartments: NumericDictionary<ResolvedCompartmentStatus>;
    doors: NumericDictionary<ResolvedDoorStatus>;
    hooked?: Classification;
    lastEvent: ResolvedLastTrailerEvent;
    reefer?: ResolvedReeferStatus;
}

export interface ResolvedTrailerConnection extends OmitFromGenerated<TrailerConnection, 'trailer'> {
    trailer?: ResolvedTrailer;
    trailerReference: NamedTrailerReference;
    trailerStatus?: ResolvedTrailerStatus;
}

export type ResolvedDeviceStatus = DeepPojoModel<DeviceStatus>;

export interface ResolvedVehicle extends OmitFromGenerated<Vehicle, 'category'> {
    category: VehicleCategory;
    deviceType?: DeviceType;
    vehicleType?: VehicleType;
}

export interface ResolvedVehicleDriverHoursStatus
    extends OmitFromGenerated<VehicleDriverHoursStatus, 'coDriverHoursStatus' | 'driverHoursStatus'> {
    coDriverHoursStatus?: ResolvedDriverHoursStatus;
    driverHoursStatus?: ResolvedDriverHoursStatus;
}

export interface ResolvedDriverHoursStatus extends OmitFromGenerated<DriverHoursStatus, 'activity' | 'subActivity'> {
    activity?: DriverActivityType;
    subActivity?: DriverSubActivityType;
}

export interface ResolvedDriver extends OmitFromGenerated<Driver, 'init' | 'toJSON'> {}

export enum TrailerType {
    REEFER = 'reefer',
    TRAILER = 'trailer',
}

export enum AssetConnectionStatus {
    CONNECTED = 'connected',
    NOT_CONNECTED = 'not-connected',
}

export interface ResolvedVehicleConnection {
    canManuallyDisconnect: boolean;
    isManuallyConnected: boolean;
    vehicle?: ResolvedVehicle;
    vehicleDriverHoursStatus?: ResolvedVehicleDriverHoursStatus;
    vehicleReference: NamedVehicleReference;
}

export enum AssetRecipientType {
    DEPOT = 'depot',
    DRIVER = 'driver',
    GROUP = 'group',
    VEHICLE = 'vehicle',
}

export interface AssetRecipient {
    enabledAssetIds?: number[];
    ids: number[];
    name: string;
    secondaryTextFirst?: string;
    secondaryTextSecond?: string;
    secondaryTextThird?: string;
    type: AssetRecipientType;
}

export interface ResolvedConversation extends OmitFromGenerated<Conversation, 'messages'> {
    messages: ResolvedMessage[];
}

export enum InhibitorStatusEnum {
    Disabled = 2,
    DisabledByIButton = 5,
    DisabledByPin = 3,
    Enabled = 1,
    EnabledByIButton = 4,
    Unknown = 0,
}

export enum MessageBodyType {
    Text = 'text',
    Workflow = 'workflow',
}

export enum MessageStatus {
    Deleted = 'deleted',
    Delivered = 'delivered',
    Failed = 'failed',
    GeneralFailure = 'general-failure',
    IncomingRead = 'incoming-read',
    OutgoingRead = 'outgoing-read',
    Pending = 'pending',
    Received = 'received',
    Scheduled = 'scheduled',
    Sending = 'sending',
    ValidationFailure = 'validation-failure',
}

export enum TachoDataBlockTypes {
    ActivityData = 64,
    DetailedSpeed = 4,
    DriverCard = 48,
    EventsAndFaults = 2,
    Overview = 1,
    TechnicalData = 8,
}

export enum GeozoneType {
    Checkpoint,
    NoEntryZone,
}

export interface ResolvedMessage {
    body: ResolvedMessageBody;
    direction: MessageDirection;
    replyToMessage?: ResolvedMessage;
    status: MessageStatus;
    value: Message;
}

export interface ResolvedMessageBody {
    type: MessageBodyType;
    value: MessageBody;
    workflowFormDefinition?: WorkflowFormDefinition;
    workflowFormDefinitionStatus?: WorkflowFormDefinitionStatus;
}

export interface ResolvedConversationSummary extends OmitFromGenerated<ConversationSummary, 'lastMessage'> {
    lastMessage?: ResolvedMessage | undefined;
}

export enum WorkflowFormDefinitionStatus {
    // Loading the form definition
    PENDING = 'pending',

    // Failed to load the form definition
    REJECTED = 'rejected',

    // Form definition is required, but not available in the store yet. Visually equivalent to pending
    REQUIRED = 'required',

    // Form definition is available
    RESOLVED = 'resolved',
}

export interface ResolvedConversationUpdate extends OmitFromGenerated<ConversationUpdate, 'updatedMessages'> {
    updatedMessages: ResolvedMessage[];
}

export interface ResolvedRouteStatus extends OmitFromGenerated<RouteStatus, 'status'> {
    status: Classification;
}

export interface ResolvedContainer extends OmitFromGenerated<Container, 'init' | 'toJSON'> {}

export interface ResolvedContainerStatus extends OmitFromGenerated<ContainerStatus, 'lastEvent' | 'temperatures'> {
    lastEvent: ResolvedLastContainerEvent;
    temperatures: ResolvedContainerTemperatureStatus[];
}

export interface ResolvedLastContainerEvent extends OmitFromGenerated<LastContainerEvent, 'eventType'> {
    eventType: Classification;
}

export interface ResolvedContainerTemperatureStatus extends OmitFromGenerated<ContainerTemperatureStatus, 'type'> {
    type: Classification;
}

export interface ResolvedAttachment extends OmitFromGenerated<Attachment, 'type'> {
    type: Classification;
}

export interface ResolvedTrailerEvent
    extends OmitFromGenerated<TrailerEvent, 'battery' | 'compartments' | 'doors' | 'eventType' | 'hooked' | 'reefer'> {
    battery?: ResolvedTrailerBatteryStatus;
    compartments: NumericDictionary<ResolvedCompartmentStatus>;
    deviceType?: DeviceType;
    doors: NumericDictionary<ResolvedDoorStatus>;
    eventType: Classification;
    hooked?: Classification;
    reefer?: ResolvedReeferStatus;
}

export interface ResolvedCompletedActivity
    extends OmitFromGenerated<CompletedActivity, 'activityType' | 'subActivityType'> {
    activityType: DriverActivityType;
    subActivityType?: DriverSubActivityType;
}

export interface ResolvedDriverEventFieldValue extends OmitFromGenerated<DriverEventFieldValue, 'fieldId'> {
    field: DriverEventField;
}

export interface ResolvedDriverEvent extends OmitFromGenerated<DriverEvent, 'completedActivity' | 'eventType'> {
    completedActivity?: ResolvedCompletedActivity;
    eventType?: DriverEventType;
}

export interface ResolvedRtdsSession extends OmitFromGenerated<RtdsSession, 'driver' | 'error' | 'vehicle'> {
    dataBlocks?: TachographDataBlock[];
    driver?: Driver;
    error?: ResolvedRtdsSessionError;
    vehicle?: Vehicle;
    vehicleStatus?: VehicleStatus;
}

export interface ResolvedRtdsSessionError {
    rtdsSessionErrorType?: Classification;
    tachographDataBlocks?: Classification[];
}

export interface ResolvedRtdsSchedule
    extends OmitFromGenerated<RtdsSchedule, 'description' | 'frequency' | 'priority'> {
    asset?: Asset;
    dataBlocks?: TachographDataBlock[];
    deviceType?: DeviceType;
    resolvedDescription: ResolvedRtdsScheduleDescription;
    scheduleFrequency: Classification;
    schedulePriority: Classification;
}

export interface ResolvedRtdsScheduleDescription {
    day?: number;
    ordinalType?: Classification;
    rtdsScheduleType?: Classification;
    weekdayType?: Classification;
}

export interface ResolvedTachoFile extends OmitFromGenerated<TachoFile, 'metadata' | 'sendVehicle'> {
    metadata: ResolvedTachoFileMetadata;
    sendVehicle?: ResolvedSendVehicle;
}

export interface ResolvedTachoFileMetadata {
    dataBlocks?: TachographDataBlock[];
    driver?: Driver;
    value: TachoFileMetadata;
    vehicle?: Vehicle;
}

export interface ResolvedSendVehicle extends OmitFromGenerated<SendVehicle, 'vehicle'> {
    vehicle?: Vehicle;
}
export interface ResolvedAdminVehicleRead
    extends OmitFromGenerated<AdminVehicleRead, 'category' | 'depot' | 'device' | 'settings'> {
    category: VehicleCategory;
    depot?: Depot;
    device?: ResolvedAdminDevice;
    deviceType?: DeviceType;
    settings?: ResolvedVehicleSettingsRead;
    vehicleType?: VehicleType;
}

export interface ResolvedVehicleType extends OmitFromGenerated<VehicleType, 'axles' | 'emissionClass' | 'type'> {
    axles?: Classification;
    emissionClass?: Classification;
    type?: Classification;
}

export interface ResolvedVehicleSettingsRead
    extends OmitFromGenerated<
        VehicleSettingsRead,
        | 'imageCapturingType'
        | 'inhibitorServiceType'
        | 'navigationType'
        | 'primaryFuelTankType'
        | 'secondaryFuelTankType'
        | 'tachoGeneration'
        | 'tachograph'
        | 'tachoProtocol'
        | 'tellTaleServiceType'
        | 'workflowType'
    > {
    imageCapturingType: Classification;
    inhibitorServiceType: Classification;
    navigationType: Classification;
    primaryFuelTankType: Classification;
    secondaryFuelTankType: Classification;
    tachoGeneration: Classification;
    tachograph: Classification;
    tachoProtocol: Classification;
    tellTaleServiceType: Classification;
    workflowType: Classification;
}

export interface ResolvedAdminVehicleEdit
    extends OmitFromGenerated<AdminVehicleEdit, 'category' | 'depot' | 'deviceId' | 'settings'> {
    category?: VehicleCategory;
    depot?: Depot;
    device?: ResolvedAdminDevice;
    deviceType?: DeviceType;
    settings?: ResolvedVehicleSettingsEdit;
    vehicleType?: VehicleType;
}

export interface ResolvedVehicleSettingsEdit
    extends OmitFromGenerated<
        VehicleSettingsEdit,
        | 'imageCapturingType'
        | 'inhibitorServiceType'
        | 'navigationType'
        | 'tachoGeneration'
        | 'tachograph'
        | 'tachoProtocol'
        | 'tellTaleServiceType'
        | 'workflowType'
    > {
    imageCapturingType: ResolvedSettingsIntegerValue;
    inhibitorServiceType: ResolvedSettingsIntegerValue;
    navigationType: ResolvedSettingsIntegerValue;
    tachoGeneration: ResolvedSettingsIntegerValue;
    tachograph: ResolvedSettingsIntegerValue;
    tachoProtocol: ResolvedSettingsIntegerValue;
    tellTaleServiceType: ResolvedSettingsIntegerValue;
    workflowType: ResolvedSettingsIntegerValue;
}

export interface ResolvedSettingsIntegerValue extends OmitFromGenerated<SettingsIntegerValue, 'value'> {
    value: Classification;
}

export interface ResolvedAdminDevice
    extends OmitFromGenerated<AdminDevice, 'billingGroup' | 'billingStatus' | 'networkId'> {
    billingGroup?: Classification;
    billingStatus?: Classification;
    networkId: Classification;
}

export interface ResolvedRtdsScheduleChangelogChanges
    extends OmitFromGenerated<
        RtdsScheduleChangelogChanges,
        'frequency' | 'priority' | 'tachographDataBlocks' | 'weekDay'
    > {
    frequency?: Classification;
    priority?: Classification;
    tachoBlocks?: TachographDataBlock[];
    weekDay?: Classification;
}

export interface ResolvedVehicleChangelogChanges
    extends OmitFromGenerated<VehicleChangelogChanges, 'category' | 'depot' | 'settings'> {
    category?: VehicleCategory;
    depot?: Depot;
    settings?: ResolvedVehicleSettingsChangelogChanges;
    vehicleType?: VehicleType;
}

export interface ResolvedVehicleSettingsChangelogChanges
    extends OmitFromGenerated<
        VehicleSettingsChangelogChanges,
        | 'imageCapturingType'
        | 'inhibitorServiceType'
        | 'navigationType'
        | 'primaryFuelTankType'
        | 'secondaryFuelTankType'
        | 'tachoGeneration'
        | 'tachograph'
        | 'tachoProtocol'
        | 'tellTaleServiceType'
        | 'workflowType'
    > {
    imageCapturingType?: Classification;
    inhibitorServiceType?: Classification;
    navigationType?: Classification;
    primaryFuelTankType?: Classification;
    secondaryFuelTankType?: Classification;
    tachoGeneration?: Classification;
    tachograph?: Classification;
    tachoProtocol?: Classification;
    tellTaleServiceType?: Classification;
    workflowType?: Classification;
}

export interface ResolvedAdminDriverRead
    extends OmitFromGenerated<AdminDriverRead, 'employersCountryCode' | 'residenceCountryCode' | 'smartCardLogin'> {
    companyCountry?: Country;
    depot?: PojoModel<Depot>;
    driverCountry?: Country;
    smartCardLogin?: ResolvedSmartCardDriverLogin;
}

export interface ResolvedSmartCardDriverLogin extends OmitFromGenerated<SmartCardDriverLogin, 'nationNumCode'> {
    country?: PojoModel<Country>;
}

export interface ResolvedSmartCardDriverLoginChangelogChanges
    extends OmitFromGenerated<SmartCardDriverLoginChangelogChanges, 'nationNumCode'> {
    country?: PojoModel<Country>;
}

export interface ResolvedDriverChangelogChanges
    extends OmitFromGenerated<DriverChangelogChanges, 'buttonLogin' | 'depotId' | 'manualLogin' | 'smartCardLogin'> {
    buttonLogin?: PojoModel<ButtonDriverLoginChangelogChanges>;
    depot?: PojoModel<Depot>;
    manualLogin?: PojoModel<ManualDriverLoginChangelogChanges>;
    smartCardLogin?: PojoModel<SmartCardDriverLoginChangelogChanges>;
}

export interface ResolvedDriverChangelog extends OmitFromGenerated<DriverChangelog, 'after' | 'before'> {
    after?: ResolvedDriverChangelogChanges;
    before?: ResolvedDriverChangelogChanges;
}

export interface ChartPeriod {
    startDate: Date;
    stopDate: Date;
}

export interface ResolvedVehicleTypeChangelogChanges
    extends OmitFromGenerated<
        VehicleTypeChangelogChanges,
        'axles' | 'dimensions' | 'emissionClass' | 'speedLimit' | 'type'
    > {
    axles?: Classification;
    dimensions?: VehicleTypeDimensions;
    emissionClass?: Classification;
    speedLimit?: SpeedLimit;
    type?: Classification;
}

export type { DeepPartial };

export type ResolvedTranslateMessageResponse = PojoModel<TranslateMessageResponse>;

export type ResolvedDriverActivityShift = PojoModel<DriverActivityShift>;
