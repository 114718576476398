import type { FC } from 'react';

import type { BatteryLevelProps } from './models';
import { BatteryLevelBar, BatteryLevelLabel, BatteryOutline } from './styles';

const BatteryLevel: FC<BatteryLevelProps> = (props) => {
    const { batteryLevel } = props;

    return (
        <BatteryOutline>
            <BatteryLevelBar batteryLevel={batteryLevel} data-testid="battery-level-span" />
            <BatteryLevelLabel>{batteryLevel}%</BatteryLevelLabel>
        </BatteryOutline>
    );
};
BatteryLevel.displayName = 'BatteryLevel';

export { BatteryLevel };
