import { ActionButtonGroup } from '@fv/components';
import Route from '@mui/icons-material/Route';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { LocationSection } from '~/common';
import { ensureAuthorizationSelector } from '~/components/EnsureAuthorization/selectors';
import { CompassIcon } from '~/components/Icons/CompassIcon';
import { useTranslation } from '~/components/LanguageSelector';
import { isUndefined } from '~/libs/utility';
import { SceneRoutes } from '~/routes';
import type { Address, Position } from '~/services/ApiClient';
import { copyCoordinatesToClipboard } from '~/services/Clipboard';

import type { SectionProps } from '../../../models';

const LocationComponent: React.FC<SectionProps> = (props) => {
    const { dragHandleElement, driverStatus, isCollapsed, toggleCollapsed } = props;

    const { t } = useTranslation();
    const { securables } = useSelector(ensureAuthorizationSelector);

    const position = driverStatus.location?.position as Position;

    const actionItems = useMemo(
        () => [
            {
                disabled: isUndefined(position),
                icon: <CompassIcon />,
                key: 'copy-coordinates',
                onClick: () => {
                    if (!isUndefined(position)) {
                        copyCoordinatesToClipboard(position);
                    }
                },
                title: t('copy-coordinates'),
            },
            ...(securables.routing.routes.isAllowed
                ? [
                      {
                          disabled: isUndefined(position),
                          icon: <Route />,
                          key: 'calculate-route',
                          title: t('route-calculation.calculate-route'),
                          to: `${SceneRoutes.ROUTE_CALCULATION}?${new URLSearchParams({
                              lat: String(position?.latitude),
                              lng: String(position?.longitude),
                          }).toString()}`,
                      },
                  ]
                : []),
        ],
        [position, securables.routing.routes.isAllowed, t]
    );

    const actionButtons = <ActionButtonGroup items={actionItems} />;

    return (
        <LocationSection
            actionButtons={actionButtons}
            address={driverStatus.location?.address as Address}
            datetime={driverStatus.location?.dateTime as Date}
            dragHandleElement={dragHandleElement}
            isCollapsed={isCollapsed}
            position={position}
            toggleCollapsed={toggleCollapsed}
        />
    );
};

LocationComponent.displayName = 'Location';
export default LocationComponent;
