import { actionGenerator } from '~/common';

const MODULES_PREFIX = 'LANDMARKS_ADMINISTRATION';
const ACTIONS_KEYS = {
    GET_LANDMARK_WITH_ASSIGNMENTS: `[${MODULES_PREFIX}] GET_LANDMARK_WITH_ASSIGNMENTS`,
    LIST: `[${MODULES_PREFIX}] LIST`,
} as const;

const ACTIONS = {
    ...actionGenerator(ACTIONS_KEYS, MODULES_PREFIX),
};

export { ACTIONS, ACTIONS_KEYS };
