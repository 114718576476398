import { compose, setDisplayName, withProps } from 'react-recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import type { Omit } from 'utility-types';

import type { InjectedDisplayPreferencesProps } from '~/components/DisplayPreferences';
import { withDisplayPreferences } from '~/components/DisplayPreferences';
import { SettingsKey, settingDataSelector } from '~/components/EnsureSettings';
import type { InjectedFeatureFlagsProps } from '~/components/FeatureFlags';
import { withFeatureFlags } from '~/components/FeatureFlags';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { withTranslation } from '~/components/LanguageSelector';
import type { SceneListViewHeaderProps, SceneListViewProps } from '~/components/SceneList';
import { SceneListViewFactory, SceneListViewHeaderFactory } from '~/components/SceneList';
import type { MonitoringVehicleEntry } from '~/data/monitoring';
import type { NumericDictionary } from '~/libs/utility';
import type { StoreState } from '~/reducers';
import { monitoringVehicleEntryEquals } from '~/scenes/Monitoring/utils';
import type {
    Classification,
    DeviceStatus,
    DeviceType,
    DriverActivityType,
    DriverSubActivityType,
    Securables,
} from '~/services/ApiClient';

import { filterMonitoringDataSourceMemoized } from './filter';
import { getColumnsMemoized, getRowId } from './grid';
import { MONITORING_LIST_VEHICLE_USERPREFERENCES_KEY, defaultVehicleListUserPreferences } from './preferences';

export type VehicleListViewProps = Omit<SceneListViewProps<MonitoringVehicleEntry>, 'columns' | 'gridDataId'>;

export type VehicleListViewHeaderProps = Omit<
    SceneListViewHeaderProps<MonitoringVehicleEntry>,
    'columns' | 'excelSheetTitle'
>;

const VehicleListViewComponent = SceneListViewFactory<MonitoringVehicleEntry>(
    MONITORING_LIST_VEHICLE_USERPREFERENCES_KEY,
    defaultVehicleListUserPreferences,
    getRowId,
    monitoringVehicleEntryEquals,
    filterMonitoringDataSourceMemoized
);

const VehicleListViewHeaderComponents = SceneListViewHeaderFactory<MonitoringVehicleEntry>(
    MONITORING_LIST_VEHICLE_USERPREFERENCES_KEY,
    defaultVehicleListUserPreferences,
    filterMonitoringDataSourceMemoized,
    'monitoring'
);

export const canViewTrailers = (securables: Securables): boolean => {
    if (securables) {
        return !!securables.monitoring && securables.monitoring.trailers;
    }
    return false;
};

export const canViewRouting = (securables: Securables): boolean => {
    return securables.routing.navigation;
};

export const canViewInhibitor = (securables: Securables): boolean => {
    return securables.services.inhibitorService;
};

export const canViewDriverStatus = (securables: Securables): boolean => {
    return securables.monitoring.driverStatus;
};

interface ReduxProps {
    deviceStatus: NumericDictionary<DeviceStatus>;
    deviceTypes: NumericDictionary<DeviceType>;
    driverActivityTypes: NumericDictionary<DriverActivityType>;
    driverSubActivityTypes: NumericDictionary<DriverSubActivityType>;
    inhibitorStatuses: NumericDictionary<Classification>;
    reeferStatuses: NumericDictionary<Classification>;
    routeStatusTypes: NumericDictionary<Classification>;
    securables: Securables;
    trailerEvents: NumericDictionary<Classification>;
}

const reduxSelector = createStructuredSelector<StoreState, ReduxProps>({
    deviceStatus: settingDataSelector(SettingsKey.DEVICESTATUS),
    deviceTypes: settingDataSelector(SettingsKey.DEVICETYPES),
    driverActivityTypes: settingDataSelector(SettingsKey.DRIVERACTIVITYTYPES),
    driverSubActivityTypes: settingDataSelector(SettingsKey.DRIVERSUBACTIVITYTYPES),
    inhibitorStatuses: settingDataSelector(SettingsKey.INHIBITOR_STATUS),
    reeferStatuses: settingDataSelector(SettingsKey.REEFER_STATUS),
    routeStatusTypes: settingDataSelector(SettingsKey.ROUTESTATUSTYPES),
    securables: settingDataSelector(SettingsKey.SECURABLES),
    trailerEvents: settingDataSelector(SettingsKey.TRAILER_EVENT_TYPE),
});

export const VehicleListView = compose<SceneListViewProps<MonitoringVehicleEntry>, VehicleListViewProps>(
    setDisplayName('VehicleListView'),
    withDisplayPreferences(),
    withTranslation(),
    connect(reduxSelector),
    withFeatureFlags(),
    withProps(
        (
            ownProps: InjectedDisplayPreferencesProps &
                InjectedFeatureFlagsProps &
                InjectedTranslationProps &
                ReduxProps
        ) => ({
            columns: getColumnsMemoized({
                canViewDriverStatus: canViewDriverStatus(ownProps.securables),
                canViewInhibitor: canViewInhibitor(ownProps.securables),
                canViewRouting: canViewRouting(ownProps.securables),
                canViewTrailers: canViewTrailers(ownProps.securables),
                deviceTypes: ownProps.deviceTypes,
                driverActivityTypes: ownProps.driverActivityTypes,
                driverDisplayNameFormat: ownProps.displayPreferences?.driverDisplayFormat,
                driverSubActivityTypes: ownProps.driverSubActivityTypes,
                featureFlags: ownProps.featureFlags,
                inhibitorStatuses: ownProps.inhibitorStatuses,
                reeferStatuses: ownProps.reeferStatuses,
                routeStatusTypes: ownProps.routeStatusTypes,
                t: ownProps.t,
                trailerDisplayNameFormat: ownProps.displayPreferences?.trailerDisplayFormat,
                trailerEvents: ownProps.trailerEvents,
                unitSystem: ownProps.displayPreferences.unitSystem,
                vehicleDisplayNameFormat: ownProps.displayPreferences?.vehicleDisplayFormat,
            }),
            gridDataId: 'monitoring-grid-vehicle',
        })
    )
)(VehicleListViewComponent);

export const VehicleListViewHeader = compose<
    SceneListViewHeaderProps<MonitoringVehicleEntry>,
    VehicleListViewHeaderProps
>(
    setDisplayName('VehicleListViewHeader'),
    withDisplayPreferences(),
    withTranslation(),
    connect(reduxSelector),
    withFeatureFlags(),
    withProps(
        (
            ownProps: InjectedDisplayPreferencesProps &
                InjectedFeatureFlagsProps &
                InjectedTranslationProps &
                ReduxProps
        ) => ({
            columns: getColumnsMemoized({
                canViewDriverStatus: canViewDriverStatus(ownProps.securables),
                canViewInhibitor: canViewInhibitor(ownProps.securables),
                canViewRouting: canViewRouting(ownProps.securables),
                canViewTrailers: canViewTrailers(ownProps.securables),
                deviceTypes: ownProps.deviceTypes,
                driverActivityTypes: ownProps.driverActivityTypes,
                driverDisplayNameFormat: ownProps.displayPreferences?.driverDisplayFormat,
                driverSubActivityTypes: ownProps.driverSubActivityTypes,
                featureFlags: ownProps.featureFlags,
                inhibitorStatuses: ownProps.inhibitorStatuses,
                reeferStatuses: ownProps.reeferStatuses,
                routeStatusTypes: ownProps.routeStatusTypes,
                t: ownProps.t,
                trailerDisplayNameFormat: ownProps.displayPreferences?.trailerDisplayFormat,
                trailerEvents: ownProps.trailerEvents,
                unitSystem: ownProps.displayPreferences.unitSystem,
                vehicleDisplayNameFormat: ownProps.displayPreferences?.vehicleDisplayFormat,
            }),
            excelSheetTitle: ownProps.t('monitoring'),
        })
    )
)(VehicleListViewHeaderComponents);
