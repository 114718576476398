import type { GroupingState, Sorting, TableColumnWidthInfo } from '@devexpress/dx-react-grid';
import type { Duration } from 'moment';
import type { ComponentType, PropsWithChildren, ReactNode } from 'react';

import type { AutoSizerInjectedProps } from '../AutoSizer';
import type { GroupSummaryItemCustom, SceneListViewHeaderProps, SceneListViewProps } from '../SceneList';

import type { Options } from './components/FilterCell/components/FilterOptionsCell/models';

export type GridRowId = number | string;
export type GridSorting = Sorting;

export interface GridColumnDefinition<T> {
    abbreviation?: string;
    align?: 'center' | 'left' | 'right';
    cellFiltering?: CellFilteringOptions;
    cellRender?: (children: ReactNode) => ReactNode;
    compare: (a: unknown, b: unknown) => number;
    dataType: 'boolean' | 'date' | 'duration' | 'number' | 'object' | 'string';
    excelCellFormat?: ((value?: unknown) => string) | string;
    exportValueFormatter?: (value?: unknown) => number | string | undefined;
    formatUndefinedValue?: boolean;
    getCellValue?: (row: T) => unknown;
    getFilterValue?: (value?: unknown) => Date | Duration | number | string | undefined;
    groupingCriteria?: (value: unknown) => { key: number | string; value?: ReactNode };
    groupingDisabled?: boolean;
    groupTitle: string;
    groupValueFormatterComponent?: React.ComponentType<{ value?: unknown }>;
    minWidth?: number;
    name: string;
    title: string;
    tooltipTitle?: string;
    valueFormatterComponent?: React.ComponentType<{ value?: unknown }>;
    valueTextFormatter?: (value?: unknown) => string | undefined;
}

export interface CellFilteringOptions {
    options: Options;
    renderOptions?: (props?: unknown) => JSX.Element;
    valueEquals?: (filterValue: unknown, cellValue: unknown) => boolean;
}

export interface CreateColumnOptions {
    abbreviation?: string;
    align?: 'center' | 'left' | 'right';
    cellFiltering?: CellFilteringOptions;
    compare?: (a: unknown, b: unknown) => number;
    excelCellFormat?: string;
    exportValueFormatter?: (value?: unknown) => number | string | undefined;
    formatUndefinedValue?: boolean;
    getFilterValue?: (value?: unknown) => Date | Duration | number | string | undefined;
    groupingCriteria?: (value: unknown) => { key: number | string; value?: React.ReactNode };
    groupingDisabled?: boolean;
    groupValueFormatterComponent?: React.ComponentType<{ value?: unknown }>;
    tooltipTitle?: string;
    valueFormatterComponent?: React.ComponentType<{ value?: unknown }>;
    valueTextFormatter?: (value?: unknown) => string | undefined;
}

export interface GridFilter {
    columnName: string;
    operation?: GridFilterOperation;
    value?: string;
}

export enum GridFilterOperation {
    After = 'after',
    AfterOrEqual = 'afterOrEqual',
    Before = 'before',
    BeforeOrEqual = 'beforeOrEqual',
    Contains = 'contains',
    EndsWith = 'endsWith',
    Equal = 'equal',
    GreaterThan = 'greaterThan',
    GreaterThanOrEqual = 'greaterThanOrEqual',
    LessThan = 'lessThan',
    LessThanOrEqual = 'lessThanOrEqual',
    MonthEqual = 'monthEqual',
    NotContains = 'notContains',
    NotEqual = 'notEqual',
    StartsWith = 'startsWith',
    YearEqual = 'yearEqual',
}

export interface FilterDurationValue {
    max: string;
    min: string;
}

export interface FilterDateTimeValue {
    max: string;
    min: string;
}
export interface GridVirtualTableElement {
    scrollIntoView: (gridRowId: GridRowId) => void;
}

export type GridHeaderProps<T> = Omit<SceneListViewHeaderProps<T>, 'columns' | 'excelSheetTitle' | 'moduleBarActions'>;
export type GridListProps<T> = Omit<SceneListViewProps<T>, 'columns' | 'getCellClassName' | 'gridDataId'>;

export type FilterDataSourceProps<T> = (
    searchQuery: string | undefined,
    dataSource: T[],
    columnDefinitions: GridColumnDefinition<T>[],
    visibleColumns: string[]
) => T[];

export interface GroupingStateColumnProps<T> {
    columnDefinitions: GridColumnDefinition<T>[];
    groupBy: string[];
    groupingEnabled: boolean;
}

export type GroupingStateProps<T> = (
    groupingStateColumnProps: GroupingStateColumnProps<T>
) => GroupingState.ColumnExtension[];

export interface GridProps<T> {
    changeColumnOrder: (columnOrder: string[]) => void;
    changeColumnWidths: (nextColumnWidths: TableColumnWidthInfo[]) => void;
    changeFilters: (filters: GridFilter[]) => void;
    changeGroupBy: (groupBy: string[]) => void;
    changeSorting: (sorting: Sorting[]) => void;
    columnDefinitions: Array<GridColumnDefinition<T>>;
    columnOrder: string[];
    columnWidths: TableColumnWidthInfo[];
    currentSelection?: GridRowId[];
    dataSource: T[];
    enableMultiselection: boolean;
    filteringEnabled: boolean;
    filters: GridFilter[];
    getCellClassName?: (row: T, columnName: string, value: unknown) => string | undefined;
    getRowId: (row: T) => GridRowId;
    gridDataId?: string;
    groupBy: string[];
    groupingEnabled: boolean;
    hideToolBar?: boolean;
    highlightedRowId?: GridRowId;
    multiSelection?: (rowIds: GridRowId[]) => void;
    noDataMessage?: string;
    onRowContextMenu?: (event: MouseEvent, rowId: GridRowId) => void;
    pinnedColumns: string[];
    RowDetail?: ComponentType<PropsWithChildren<{ row: T }>>;
    rowEquals: (rowA: T, rowB: T) => boolean;
    selectedRowId?: GridRowId;
    selectRow?: (rowId: GridRowId) => void;
    sorting: Sorting[];
    summaryItems?: GroupSummaryItemCustom[];
    visibleColumns: string[];
}
export interface GridForwardRefProps {
    forwardRef?: React.Ref<GridVirtualTableElement>;
}

export interface GridInnerProps<T> extends GridProps<T>, AutoSizerInjectedProps, GridForwardRefProps {}
