import type { HistoryMapStoreState } from './models';

const MONITORING_USERPREFERENCES_KEY = 'monitoring-state';
const MONITORING_VEHICLE_ASSETSELECTOR_USERPREFERENCES_KEY = 'monitoring-vehicle-assetselector-state';
const MONITORING_TRAILER_ASSETSELECTOR_USERPREFERENCES_KEY = 'monitoring-trailer-assetselector-state';
const MONITORING_CONTAINER_ASSETSELECTOR_USERPREFERENCES_KEY = 'monitoring-container-assetselector-state';
const MONITORING_DATETIMERANGEPICKER_USERPREFERENCES_KEY = 'monitoring-datetimerangepicker-state';
const MONITORING_DRIVER_ASSETSELECTOR_USERPREFERENCES_KEY = 'monitoring-driver-assetselector-state';

enum AssetSubpage {
    CODRIVER = 'co-driver',
    DRIVER = 'driver',
    HISTORY = 'history',
}

enum MonitoringPerspective {
    CONTAINER = 'container',
    DRIVER = 'driver',
    TRAILER = 'trailer',
    VEHICLE = 'vehicle',
}

enum MonitoringViewMode {
    LIST = 'list',
    MAP = 'map',
}

const defaultHistoryMapStoreState: HistoryMapStoreState = {
    flyToPositionGroup: false,
    panToPosition: false,
    panToPositionGroup: false,
    viewport: undefined,
};

enum SectionName {
    DEVICESTATUS = 'device-status',
    EBS = 'EBS',
    FUELLEVEL = 'fuel-level',
    LATESTMESSAGES = 'latest-messages',
    LOCATION = 'location',
    MINIMAP = 'mini-map',
    NAVIGATION = 'navigation',
    STAFF = 'staff',
    TEMPERATURES = 'temperatures',
    TRAILERS = 'trailers',
    VEHICLEDETAILS = 'vehicle-details',
}

const maxNumberOfMessagesToDisplay = 5;

export {
    AssetSubpage,
    defaultHistoryMapStoreState,
    maxNumberOfMessagesToDisplay,
    MONITORING_CONTAINER_ASSETSELECTOR_USERPREFERENCES_KEY,
    MONITORING_DATETIMERANGEPICKER_USERPREFERENCES_KEY,
    MONITORING_DRIVER_ASSETSELECTOR_USERPREFERENCES_KEY,
    MONITORING_TRAILER_ASSETSELECTOR_USERPREFERENCES_KEY,
    MONITORING_USERPREFERENCES_KEY,
    MONITORING_VEHICLE_ASSETSELECTOR_USERPREFERENCES_KEY,
    MonitoringPerspective,
    MonitoringViewMode,
    SectionName,
};
