import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import type { KeyValueItem } from '~/components/KeyValueList';
import { KeyValueList } from '~/components/KeyValueList';
import { ReorderableSection } from '~/components/Sections';
import { BatteryLevel } from '~/scenes/Monitoring/components/BatteryLevel';

import type { CommonSectionProps } from '../../models';

const DeviceStatusSection: FC<CommonSectionProps> = (props) => {
    const { dragHandleElement, entry, isCollapsed, toggleCollapsed } = props;
    const { deviceStatus } = entry;
    const { t } = useTranslation();

    const keyValueItems: KeyValueItem[] = [
        {
            label: t('battery-level'),
            value: deviceStatus ? <BatteryLevel batteryLevel={deviceStatus.batteryLevel} /> : '',
            valueDataId: 'battery-level',
        },
    ];

    return (
        <ReorderableSection
            data-testid="device-status-section"
            dataId="device-status-section"
            dragHandleElement={dragHandleElement}
            isCollapsed={isCollapsed}
            title={t('device-status')}
            toggleCollapsed={toggleCollapsed}
        >
            <KeyValueList list={keyValueItems} listDataId="device-status-section-list" />
        </ReorderableSection>
    );
};

DeviceStatusSection.displayName = 'DeviceStatusSection';
export { DeviceStatusSection };
