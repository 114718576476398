import type { ColorContrast, ColorContrastOptions, FixedArray, Theme, ThemeOptions } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';

import logoUrl from '~/assets/addsecure_rgb.png';
import { merge } from '~/libs/utility';

import {
    androidGreen,
    baraRed,
    blueMartina,
    energos,
    lavenderRose,
    mediterraneanSea,
    radianYellow,
    sunflower,
} from './colors';

export const createAppTheme = (
    customThemeOptions?: Pick<ThemeOptions, 'functionalItemsColors' | 'menuBar' | 'palette' | 'partner'>
) => {
    const baseThemeOptions: ThemeOptions = {
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        minWidth: 'auto',
                        // Prevent wrapping of buttons over multiple lines
                        whiteSpace: 'nowrap',
                    },
                },
            },
            MuiCheckbox: { defaultProps: { color: 'secondary' } },
            MuiChip: { styleOverrides: { root: { height: '24px' } } },
            MuiCssBaseline: {
                styleOverrides: {
                    '&::-webkit-scrollbar': { height: 7.5, width: 7.5 },
                    '&::-webkit-scrollbar-thumb': {
                        '&:active': { backgroundColor: '#606060' },
                        '&:hover': { backgroundColor: '#a6a6a6' },
                        backgroundColor: '#cdcdcd',
                        borderBottom: '1px solid white',
                        borderRight: '1px solid white',
                    },
                    '&::-webkit-scrollbar-track': { backgroundColor: '#f0f0f0' },
                    body: { fontSize: '14px', letterSpacing: '0.01071em', lineHeight: 1.43 },
                    msScrollbarBaseColor: ['#cdcdcd', '#f0f0f0'],
                    scrollbarColor: ['#cdcdcd', '#f0f0f0'],
                    scrollbarWidth: 'thin',
                },
            },
            MuiIconButton: { styleOverrides: { root: { '&.Mui-disabled': { color: 'inherit', opacity: 0.26 } } } },
            MuiInputBase: {
                defaultProps: {
                    color: 'primary',
                    inputProps: {
                        // Force required: false to not show the html5 required helpers from the browser
                        required: false,
                    },
                    size: 'small',
                },
                styleOverrides: {
                    inputSizeSmall: { fontSize: 14 },
                    root: {
                        minHeight: '40px',
                    },
                },
            },
            MuiInputLabel: { styleOverrides: { sizeSmall: { fontSize: 14 } } },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        '&.Mui-selected': {
                            '&.Mui-focusVisible': { background: '#00000014' },
                            backgroundColor: '#00000014',
                        },
                        '&.Mui-selected:hover': { backgroundColor: '#00000014' },
                        backgroundColor: 'initial',
                    },
                },
            },
            MuiRadio: { defaultProps: { color: 'secondary' } },
            MuiSelect: { defaultProps: { size: 'small' } },
            MuiSwitch: { defaultProps: { color: 'secondary' } },
            MuiTableCell: { styleOverrides: { root: { position: 'relative' } } },
            MuiTableRow: { styleOverrides: { root: { height: '32px' } } },
            MuiTooltip: { styleOverrides: { tooltip: { overflowWrap: 'break-word' } } },
            MuiTypography: { styleOverrides: { root: { overflowWrap: 'break-word' } } },
        },
        functionalItemsColors: {
            assetType: {
                container: { contrast: '#FFFFFF', value: '#001689' },
                driver: { contrast: '#FFFFFF', value: '#001689' },
                group: { contrast: '#FFFFFF', value: '#001689' },
                trailer: { contrast: '#FFFFFF', value: '#001689' },
                user: { contrast: '#FFFFFF', value: '#9C27B0' },
                vehicle: { contrast: '#FFFFFF', value: '#001689' },
            },
            batteryLevel: {
                high: { value: '#95F058' },
                low: { value: '#EF655A' },
                normal: { value: '#ffc056' },
            },
            cluster: { contrast: '#FFFFFF', value: '#000000' },
            detailsPaneHeader: { contrast: '#FFFFFF', value: '#001689' },
            driverActivity: {
                available: { contrast: '#FFFFFF', value: '#FFCF44' },
                drive: { contrast: '#FFFFFF', value: '#B15DFF' },
                rest: { contrast: '#FFFFFF', value: '#1EB980' },
                subtotal: { contrast: '#FFFFFF', value: '#f39500' },
                work: { contrast: '#FFFFFF', value: '#72DEFF' },
            },
            graph: [
                { value: sunflower[500] },
                { value: energos[500] },
                { value: blueMartina[500] },
                { value: lavenderRose[500] },
                { value: baraRed[500] },
                { value: radianYellow[500] },
                { value: androidGreen[500] },
                { value: mediterraneanSea[500] },
            ],
            ignition: {
                off: { contrast: '#FFFFFF', value: '#9E9E9E' },
                on: { contrast: '#FFFFFF', value: '#4CAF50' },
            },
            temperature: {
                high: { contrast: '#FFFFFF', value: '#EA2027' },
                low: { contrast: '#FFFFFF', value: '#0652DD' },
                return: { contrast: '#FFFFFF', value: '#FF5722' },
                sensor1: { contrast: '#FFFFFF', value: '#8BC34A' },
                sensor2: { contrast: '#FFFFFF', value: '#FF9800' },
                sensor3: { contrast: '#FFFFFF', value: '#00BCD4' },
                sensor4: { contrast: '#FFFFFF', value: '#795548' },
                setpoint: { contrast: '#FFFFFF', value: '#FFC107' },
                supply: { contrast: '#FFFFFF', value: '#3F51B5' },
            },
        },
        menuBar: {
            activeIconColor: '#FFFFFF',
            background: '#212121',
            iconColor: '#DBDBDB',
        },
        palette: {
            action: { selectedBackground: 'rgba(0, 0, 0, 0.38)' },
            background: { default: '#fafafa' },
            error: { contrastText: '#FFFFFF', dark: '#930000', light: '#FF4F30', main: '#CC0000' },
            grid: { selected: '#F6EDF5' },
            info: { contrastText: '#FFFFFF', dark: '#002165', light: '#4D72C5', main: '#004794' },
            primary: { contrastText: '#FFFFFF', dark: '#2D8B14', light: '#96EF76', main: '#63BC47' },
            secondary: { contrastText: '#FFFFFF', dark: '#630058', light: '#C759B5', main: '#942685' },
            success: { dark: '#357F00', light: '#9CE156', main: '#69AF22' },
            warning: { dark: '#C8B900', light: '#FFFF72', main: '#FFEB3B' },
            warning2: { dark: '#C66900', light: '#FFC947', main: '#FF9800' },
        },
        partner: {
            analyticsSolutionName: 'Dash',
            logoUrl,
            name: 'AddSecure',
            url: 'https://www.addsecure.com/',
        },
    };

    const mergedThemeOptions = merge(baseThemeOptions, customThemeOptions);
    const theme = createTheme(mergedThemeOptions);
    const augmentColorContrast = (colorContrastOptions: ColorContrastOptions): ColorContrast => ({
        contrast: colorContrastOptions.contrast ?? theme.palette.getContrastText(colorContrastOptions.value),
        value: colorContrastOptions.value,
    });

    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    return <Theme>{
        ...theme,
        custom: {
            chart: { minHeight: 400 },
            dialogContent: { minHeight: 500, minWidth: 500 },
            font: {
                fontFamily: 'Roboto, Arial, sans-serif',
                fontSize: '14px',
                fontWeight: 500,
            },
            header: { height: 56 },
            inputContainer: { display: 'grid', gap: 10, gridTemplateColumns: '400px 42px' },
            listMaxHeight: '40vh',
            loader: { minHeight: 400 },
            menu: { width: 65 },
            subtitleFontSize: '15px',
            virtualList: { height: 300 },
        },
        functionalItemsColors: {
            assetType: {
                container: augmentColorContrast(mergedThemeOptions.functionalItemsColors!.assetType!.container!),
                driver: augmentColorContrast(mergedThemeOptions.functionalItemsColors!.assetType!.driver!),
                group: augmentColorContrast(mergedThemeOptions.functionalItemsColors!.assetType!.group!),
                trailer: augmentColorContrast(mergedThemeOptions.functionalItemsColors!.assetType!.trailer!),
                user: augmentColorContrast(mergedThemeOptions.functionalItemsColors!.assetType!.user!),
                vehicle: augmentColorContrast(mergedThemeOptions.functionalItemsColors!.assetType!.vehicle!),
            },
            batteryLevel: {
                high: augmentColorContrast(mergedThemeOptions.functionalItemsColors!.batteryLevel!.high!),
                low: augmentColorContrast(mergedThemeOptions.functionalItemsColors!.batteryLevel!.low!),
                normal: augmentColorContrast(mergedThemeOptions.functionalItemsColors!.batteryLevel!.normal!),
            },
            cluster: augmentColorContrast(mergedThemeOptions.functionalItemsColors!.cluster!),
            detailsPaneHeader: augmentColorContrast(mergedThemeOptions!.functionalItemsColors!.detailsPaneHeader!),
            driverActivity: {
                available: augmentColorContrast(mergedThemeOptions.functionalItemsColors!.driverActivity!.available!),
                drive: augmentColorContrast(mergedThemeOptions.functionalItemsColors!.driverActivity!.drive!),
                rest: augmentColorContrast(mergedThemeOptions.functionalItemsColors!.driverActivity!.rest!),
                subtotal: augmentColorContrast(mergedThemeOptions.functionalItemsColors!.driverActivity!.subtotal!),
                work: augmentColorContrast(mergedThemeOptions.functionalItemsColors!.driverActivity!.work!),
            },
            graph: mergedThemeOptions.functionalItemsColors!.graph!.map(augmentColorContrast) as FixedArray<
                ColorContrast,
                8
            >,
            ignition: {
                off: augmentColorContrast(mergedThemeOptions.functionalItemsColors!.ignition!.off!),
                on: augmentColorContrast(mergedThemeOptions.functionalItemsColors!.ignition!.on!),
            },
            temperature: {
                high: augmentColorContrast(mergedThemeOptions.functionalItemsColors!.temperature!.high!),
                low: augmentColorContrast(mergedThemeOptions.functionalItemsColors!.temperature!.low!),
                return: augmentColorContrast(mergedThemeOptions.functionalItemsColors!.temperature!.return!),
                sensor1: augmentColorContrast(mergedThemeOptions.functionalItemsColors!.temperature!.sensor1!),
                sensor2: augmentColorContrast(mergedThemeOptions.functionalItemsColors!.temperature!.sensor2!),
                sensor3: augmentColorContrast(mergedThemeOptions.functionalItemsColors!.temperature!.sensor3!),
                sensor4: augmentColorContrast(mergedThemeOptions.functionalItemsColors!.temperature!.sensor4!),
                setpoint: augmentColorContrast(mergedThemeOptions.functionalItemsColors!.temperature!.setpoint!),
                supply: augmentColorContrast(mergedThemeOptions.functionalItemsColors!.temperature!.supply!),
            },
        },
        palette: {
            ...theme.palette,
            info: theme.palette.augmentColor({ color: mergedThemeOptions.palette!.info! }),
            success: theme.palette.augmentColor({ color: mergedThemeOptions.palette!.success! }),
            warning: theme.palette.augmentColor({ color: mergedThemeOptions.palette!.warning! }),
            warning2: theme.palette.augmentColor({ color: mergedThemeOptions.palette!.warning2! }),
        },
    };
};
