import { styled } from '@mui/material';

import type { BatteryLevelProps } from './models';

const BatteryLevelBar = styled('div')<BatteryLevelProps>(({ batteryLevel, theme }) => ({
    backgroundColor:
        batteryLevel <= 15
            ? theme.functionalItemsColors.batteryLevel.low.value // red
            : batteryLevel >= 31
              ? theme.functionalItemsColors.batteryLevel.high.value // green
              : theme.functionalItemsColors.batteryLevel.normal.value, // orange
    borderRadius: 1,
    height: '100%',
    width: `${batteryLevel}%`,
}));

const BatteryOutline = styled('div')(({ theme }) => ({
    '&:after': {
        border: `2px solid black`,
        borderRadius: '0 2px 2px 0',
        content: '" "',
        height: '70%',
        position: 'absolute',
        right: '-4px',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 0,
    },
    border: `2px solid black`,
    borderRadius: 3,
    height: theme.spacing(2.5),
    margin: 'auto',
    padding: 1,
    position: 'relative',
    width: theme.spacing(4.5),
}));

const BatteryLevelLabel = styled('span')(() => ({
    color: 'black',
    fontSize: 14,
    left: '50%',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
}));

export { BatteryLevelBar, BatteryLevelLabel, BatteryOutline };
