import type { ComponentEnhancer } from 'react-recompose';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { withTranslation } from '~/components/LanguageSelector';
import { settingsStoreStateSelector } from '~/selectors';
import type { NonEmptyArray } from '~/types';

import { withFeatureFlags } from '../FeatureFlags';

import type { EnsureSettingsDispatchProps, EnsureSettingsStateProps } from './component';
import { ensureSettingsHoc } from './component';
import { settingsReducerFactory } from './reducers';
import { mapDispatchToProps, mapStateToProps } from './redux';
import type { SettingsDetails } from './settings';
import { settingsDetails } from './settings';

export * from './data/actions';
export type { SettingsStoreState } from './reducers';
export * from './selectors';
export { SettingsKey } from './settings';

export type { SettingsDataType } from './settings';
export const settingsReducer = settingsReducerFactory(settingsDetails);

export const ensureSettings = (
    requiredSettings: NonEmptyArray<keyof SettingsDetails>,
    showLoadingWhileRetrieving: boolean = true
): ComponentEnhancer<unknown, unknown> =>
    compose(
        withTranslation(),
        withFeatureFlags(),
        connect<EnsureSettingsStateProps, EnsureSettingsDispatchProps>(
            createSelector(settingsStoreStateSelector, mapStateToProps),
            mapDispatchToProps
        ),
        ensureSettingsHoc(requiredSettings, showLoadingWhileRetrieving)
    );
