import type { StaticDataStoreState } from '~/common';
import type { SettingsStoreState } from '~/components/EnsureSettings';
import type { DynamicMonitoringStoreState, MonitoringVehicleEntry } from '~/data/monitoring';
import { buildMonitoringVehicleEntries } from '~/data/monitoring';
import type { ResolvedVehicle } from '~/services/ApiClient';

import type { DriverSubpageOuterProps } from './component';

export interface DriverSubpageReduxProps {
    entry: MonitoringVehicleEntry;
}

export const propsSelector = (_: unknown, { vehicle }: DriverSubpageOuterProps): ResolvedVehicle => vehicle;

export const mapStateToProps = (
    vehicle: ResolvedVehicle,
    staticDataStoreState: StaticDataStoreState,
    dynamicMonitoringStoreState: DynamicMonitoringStoreState,
    settings: SettingsStoreState
): DriverSubpageReduxProps => {
    return {
        entry: buildMonitoringVehicleEntries({
            canViewTrailers: settings.securables.data.monitoring.trailers,
            compartmentStatus: settings.compartmentStatus.data,
            devices: staticDataStoreState.devices.data,
            deviceStatuses: dynamicMonitoringStoreState.deviceStatuses.data,
            deviceTypes: settings.deviceTypes.data,
            doorStatus: settings.doorStatus.data,
            driverActivityTypes: settings.driverActivityTypes.data,
            driverSubActivityTypes: settings.driverSubActivityTypes.data,
            hookedStatus: settings.hookedStatus.data,
            inhibitorStatuses: settings.inhibitorStatus.data,
            reeferAlarm: settings.reeferAlarm.data,
            reeferManufacturers: settings.reeferManufacturers.data,
            reeferOperationMode: settings.reeferOperationMode.data,
            reeferPowerMode: settings.reeferPowerMode.data,
            reeferSpeed: settings.reeferSpeed.data,
            reeferStatus: settings.reeferStatus.data,
            routeStatusTypes: settings.routeStatusTypes.data,
            trailerBatteryStatus: settings.trailerBatteryStatus.data,
            trailerEventType: settings.trailerEventType.data,
            trailerManufacturers: settings.trailerManufacturers.data,
            trailersHash: staticDataStoreState.trailers.data.hash,
            trailersStatus: dynamicMonitoringStoreState.trailerStatus.data,
            vehicleCategories: settings.vehicleCategories.data,
            vehicleConnections: dynamicMonitoringStoreState.vehicleConnections.data,
            vehicleDriverHoursStatus: dynamicMonitoringStoreState.vehicleDriverHoursStatus.data,
            vehicleInhibitorStatuses: dynamicMonitoringStoreState.inhibitorStatus.data,
            vehicleRouteStatuses: dynamicMonitoringStoreState.routeStatus.vehicle.data,
            vehicles: [staticDataStoreState.vehicles.data.hash[vehicle.id]],
            vehicleStatuses: dynamicMonitoringStoreState.vehicleStatus.data,
            vehicleTypes: settings.vehicleTypes.data,
        })[0],
    };
};
