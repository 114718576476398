import { SpeedometerIcon } from '@fv/components/Icons';
import Room from '@mui/icons-material/Room';
import { List, Typography } from '@mui/material';
import type { FC } from 'react';

import { AddressCard } from '~/components/AddressCard';
import type { InjectedDisplayPreferencesProps } from '~/components/DisplayPreferences';
import { ElapsedTime } from '~/components/ElapsedTime';
import {
    AddressFormatter,
    CoordinatesFormatter,
    DirectionFormatter,
    DistanceFormatter,
    SpeedFormatter,
} from '~/components/Formatters';
import { ClockIcon, OdometerIcon } from '~/components/Icons';
import { useTranslation } from '~/components/LanguageSelector';
import { ReorderableSection } from '~/components/Sections';
import { TitledTruncatedText } from '~/components/TitledTruncatedText';
import { isUndefined } from '~/libs/utility';
import type { Address, Position } from '~/services/ApiClient';
import { AddressFormat } from '~/services/Formatters';

import { CourseOverGroundIcon } from './components/CourseOverGroundIcon';
import { useStyles } from './styles';

export interface LocationSectionProps {
    actionButtons?: JSX.Element;
    address?: Address;
    courseOverGround?: number;
    datetime?: Date;
    dragHandleElement: JSX.Element;
    isCollapsed: boolean;
    odometer?: number;
    position?: Position;
    speedOverGround?: number;
    toggleCollapsed: () => void;
}

export interface LocationSectionInnerProps extends LocationSectionProps, InjectedDisplayPreferencesProps {}

export const LocationSectionComponent: FC<LocationSectionInnerProps> = (props) => {
    const {
        actionButtons,
        address,
        courseOverGround,
        datetime,
        displayPreferences,
        dragHandleElement,
        isCollapsed,
        odometer,
        position,
        speedOverGround,
        toggleCollapsed,
    } = props;

    const classes = useStyles();
    const { t } = useTranslation();
    const routeDynamicInfo = (
        <Typography className={classes.additionalInfo} color="textSecondary" variant="caption">
            <CourseOverGroundIcon className={classes.infoIcon} courseOverGround={courseOverGround} />
            <span data-id="direction">
                <DirectionFormatter undefinedValueMessage={t('not-available')} value={courseOverGround} />
            </span>
            <div className={classes.delimiter}>•</div>
            <SpeedometerIcon className={classes.infoIcon} />
            <span data-id="speed">
                <SpeedFormatter
                    t={t}
                    undefinedValueMessage={t('not-available')}
                    unitSystem={displayPreferences.unitSystem}
                    value={speedOverGround}
                />
            </span>
            <div className={classes.delimiter}>•</div>
            <OdometerIcon className={classes.infoIcon} />
            <span data-id="odometer">
                <DistanceFormatter
                    t={t}
                    undefinedValueMessage={t('not-available')}
                    unitSystem={displayPreferences.unitSystem}
                    value={odometer}
                />
            </span>
        </Typography>
    );

    const addressCardExtraData = (
        <>
            {(!isUndefined(courseOverGround) || !isUndefined(speedOverGround) || !isUndefined(odometer)) &&
                routeDynamicInfo}
            {datetime ? (
                <ElapsedTime icon={ClockIcon} since={datetime} />
            ) : (
                <span data-id="timestamp">{t('not-available')}</span>
            )}
        </>
    );

    const addressCard = (
        <>
            <AddressCard
                address={address}
                icon={<Room color="primary" />}
                noDataMessage={t('no-location-information-available')}
                position={position}
            >
                {addressCardExtraData}
            </AddressCard>
            {!isUndefined(datetime) && actionButtons}
        </>
    );

    const getRightHeaderElementText = () => {
        if (isUndefined(address)) {
            return (
                <CoordinatesFormatter
                    t={t}
                    undefinedValueMessage={t('no-location-information-available')}
                    value={position}
                />
            );
        }

        return (
            <>
                <AddressFormatter options={{ format: AddressFormat.FirstLine }} value={address} />
                {`, `}
                <AddressFormatter options={{ format: AddressFormat.SecondLine }} value={address} />
            </>
        );
    };

    return (
        <ReorderableSection
            dataId="location"
            dragHandleElement={dragHandleElement}
            isCollapsed={isCollapsed}
            rightHeaderElement={<TitledTruncatedText>{getRightHeaderElementText()}</TitledTruncatedText>}
            title={t('location')}
            toggleCollapsed={toggleCollapsed}
        >
            <List data-id="route-dynamic-info" disablePadding>
                {addressCard}
            </List>
        </ReorderableSection>
    );
};
