import type { Dispatch } from 'redux';

import type { StaticDataStoreState } from '~/common';
import type { SettingsStoreState } from '~/components/EnsureSettings';
import type { DynamicMonitoringStoreState } from '~/data/monitoring';
import { buildMonitoringVehicleEntries } from '~/data/monitoring';
import { connectTrailerAction } from '~/data/vehicleconnections';
import { reportError } from '~/reporting';

import type { ConnectVehicleDialogProps, DispatchProps, StateProps } from './component';

export const mapStateToProps = (
    staticDataState: StaticDataStoreState,
    dynamicMonitoring: DynamicMonitoringStoreState,
    settingsState: SettingsStoreState
): StateProps => {
    return {
        vehicleCategories: Object.values(settingsState.vehicleCategories.data),
        vehicles: buildMonitoringVehicleEntries({
            canViewTrailers: settingsState.securables.data.monitoring.trailers,
            compartmentStatus: settingsState.compartmentStatus.data,
            devices: staticDataState.devices.data,
            deviceStatuses: dynamicMonitoring.deviceStatuses.data,
            deviceTypes: settingsState.deviceTypes.data,
            doorStatus: settingsState.doorStatus.data,
            driverActivityTypes: settingsState.driverActivityTypes.data,
            driverSubActivityTypes: settingsState.driverSubActivityTypes.data,
            hookedStatus: settingsState.hookedStatus.data,
            inhibitorStatuses: settingsState.inhibitorStatus.data,
            reeferAlarm: settingsState.reeferAlarm.data,
            reeferManufacturers: settingsState.reeferManufacturers.data,
            reeferOperationMode: settingsState.reeferOperationMode.data,
            reeferPowerMode: settingsState.reeferPowerMode.data,
            reeferSpeed: settingsState.reeferSpeed.data,
            reeferStatus: settingsState.reeferStatus.data,
            routeStatusTypes: settingsState.routeStatusTypes.data,
            trailerBatteryStatus: settingsState.trailerBatteryStatus.data,
            trailerEventType: settingsState.trailerEventType.data,
            trailerManufacturers: settingsState.trailerManufacturers.data,
            trailersHash: staticDataState.trailers.data.hash,
            trailersStatus: dynamicMonitoring.trailerStatus.data,
            vehicleCategories: settingsState.vehicleCategories.data,
            vehicleConnections: dynamicMonitoring.vehicleConnections.data,
            vehicleDriverHoursStatus: dynamicMonitoring.vehicleDriverHoursStatus.data,
            vehicleInhibitorStatuses: dynamicMonitoring.inhibitorStatus.data,
            vehicleRouteStatuses: dynamicMonitoring.routeStatus.vehicle.data,
            vehicles: staticDataState.vehicles.data.array,
            vehicleStatuses: dynamicMonitoring.vehicleStatus.data,
            vehicleTypes: settingsState.vehicleTypes.data,
        }),
    };
};

export const mapDispatchToProps = (dispatch: Dispatch, ownProps: ConnectVehicleDialogProps): DispatchProps => {
    return {
        connectVehicle: (vehicleId: number) => {
            dispatch(connectTrailerAction(vehicleId, ownProps.trailer.id)).catch(reportError);
        },
    };
};
