import { Component } from 'react';

import { ReloadPage } from '~/components/ReloadPage';

interface AppInnerProps {}

interface AppState {
    hasError: boolean;
}

export class ErrorBoundary extends Component<AppInnerProps, AppState> {
    private reloadPage = () => window.location.reload();

    public constructor(props: AppInnerProps) {
        super(props);
        this.state = {
            hasError: false,
        };
    }

    public componentDidCatch() {
        this.setState({ hasError: true });
    }

    public render() {
        if (this.state.hasError || this.props.children === undefined) {
            return <ReloadPage handleReload={this.reloadPage} />;
        }

        return this.props.children;
    }
}
