import { Tooltip } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import * as React from 'react';

import { TitledTruncatedText } from '~/components/TitledTruncatedText';

import type { GridColumnDefinition } from '../../models';

import type { TableHeaderRowTitleClassKey } from './styles';

export interface TableHeaderRowTitleProps<T> {
    columnDefinitions: Array<GridColumnDefinition<T>>;
}

export interface TableHeaderRowTitleInnerProps<T>
    extends TableHeaderRowTitleProps<T>,
        WithStyles<TableHeaderRowTitleClassKey> {}

export const createTableHeaderRowTitleComponent: <T>(
    columnDefinitions: Array<GridColumnDefinition<T>>
) => React.FunctionComponent<TableHeaderRowTitleInnerProps<T>> = (columnDefinitions) => (props) => {
    const columnTitle = props.children as string;

    const columnDefinition = columnDefinitions.find((c) => c.title === columnTitle);
    const abbreviation = columnDefinition && columnDefinition.abbreviation;
    if (!abbreviation) {
        return <TitledTruncatedText title={columnDefinition?.tooltipTitle}>{columnTitle}</TitledTruncatedText>;
    }

    return (
        <Tooltip title={columnDefinition?.tooltipTitle || columnTitle}>
            <span className={props.classes.title}>{abbreviation}</span>
        </Tooltip>
    );
};
