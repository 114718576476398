import { createAction } from 'redux-actions';

import { ApiClient, retryableRequest } from '~/services/ApiClient';

import { ActionTypeKeys, ActionTypePrefixes } from './actionTypes';

const retrieveDeviceStatuses = createAction(ActionTypePrefixes.DEVICE_STATUSES, () =>
    retryableRequest(() => ApiClient.getDeviceStatuses())
);

const updateDeviceStatuses = createAction(ActionTypeKeys.DEVICE_STATUSES_UPDATE);

export { retrieveDeviceStatuses, updateDeviceStatuses };
