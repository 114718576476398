import type { DeviceStatus, DeviceStatusResponse } from '~/services/ApiClient';
import type { Action, Fulfilled, Pending, Rejected } from '~/types';

enum ActionTypePrefixes {
    DEVICE_STATUSES = 'DEVICE_STATUSES',
}
enum ActionTypeKeys {
    DEVICE_STATUSES_FULFILLED = `DEVICE_STATUSES_FULFILLED`,
    DEVICE_STATUSES_PENDING = `DEVICE_STATUSES_PENDING`,
    DEVICE_STATUSES_REJECTED = `DEVICE_STATUSES_REJECTED`,
    DEVICE_STATUSES_UPDATE = `DEVICE_STATUSES_UPDATE`,
}

type ActionTypes =
    | Action<ActionTypeKeys.DEVICE_STATUSES_UPDATE, DeviceStatus>
    | Fulfilled<ActionTypeKeys.DEVICE_STATUSES_FULFILLED, DeviceStatusResponse>
    | Pending<ActionTypeKeys.DEVICE_STATUSES_PENDING>
    | Rejected<ActionTypeKeys.DEVICE_STATUSES_REJECTED>;

export { ActionTypeKeys, ActionTypePrefixes, type ActionTypes };
