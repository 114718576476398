import Chat from '@mui/icons-material/Chat';
import GpsFixed from '@mui/icons-material/GpsFixed';
import Route from '@mui/icons-material/Route';
import type { FC, ReactNode } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath } from 'react-router';

import type { ContextMenuItem, ContextMenuPosition } from '~/components/ContextMenu';
import { ContextMenu } from '~/components/ContextMenu';
import type { InjectedDisplayPreferencesProps } from '~/components/DisplayPreferences';
import {
    CompassIcon,
    ConnectIcon,
    DisconnectIcon,
    DriverIcon,
    GeneratePinInhibitorIcon,
    HistoryIcon,
    InhibitorDisabledIcon,
    InhibitorEnabledIcon,
} from '~/components/Icons';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { panToAssetAction } from '~/components/SceneMap';
import { AssetSubpage, MonitoringPerspective, MonitoringViewMode } from '~/data/monitoring';
import { isUndefined } from '~/libs/utility';
import { openConversationAction } from '~/modules/Communication';
import type { StoreState } from '~/reducers';
import { SceneRoutes } from '~/routes';
import { AssetType, InhibitorStatusEnum } from '~/services/ApiClient';
import { copyCoordinatesToClipboard } from '~/services/Clipboard';
import { formatVehicleName } from '~/services/Formatters';

import { MONITORING_PATH_STRUCTURE } from '../../consts';
import {
    ConnectTrailerDialog,
    ConnectVehicleDialog,
    DisconnectTrailerFromVehicleDialog,
    DisconnectVehicleFromTrailerDialog,
    InhibitorActionDialog,
} from '../Dialogs';
import { InhibitorAction } from '../Dialogs/InhibitorActionDialog';

import { reduxPropsSelector } from './redux';

export enum AssetContextMenuType {
    AssetSelector = 'asset-selector',
    Grid = 'grid',
    Map = 'map',
}

export interface AssetContextMenuProps {
    assetId?: number;
    assetType?: AssetType;
    menuType?: AssetContextMenuType;
    onClose: () => void;
    position?: ContextMenuPosition;
    viewMode: MonitoringViewMode;
}

export interface AssetContextMenuInnerProps
    extends AssetContextMenuProps,
        InjectedTranslationProps,
        InjectedDisplayPreferencesProps {}

export const AssetContextMenuComponent: FC<AssetContextMenuInnerProps> = (props) => {
    const { assetId, assetType, displayPreferences, menuType, onClose, position, t, viewMode } = props;

    const dispatch = useDispatch();
    const reduxProps = useSelector((state: StoreState) => reduxPropsSelector(state, { assetId, assetType }));
    const [dialog, setDialog] = useState<ReactNode>();

    const getMenuItems = (): ContextMenuItem[] => {
        if (isUndefined(assetId) || !assetType || !menuType) {
            return [];
        }

        const closeDialog = () => setDialog(undefined);
        const locateOnMap = () => {
            dispatch(
                panToAssetAction(
                    'monitoring',
                    'locate-from-context-menu',
                    'Locate on map from context menu',
                    'context-menu'
                )
            );
        };
        const openConversation = () => {
            dispatch(openConversationAction(assetId, 'context-menu'));
        };

        const copyCoordinates = () => {
            if (!isUndefined(reduxProps.assetPosition)) {
                copyCoordinatesToClipboard(reduxProps.assetPosition);
            }
        };

        const isInhibitorStatusUnknown =
            isUndefined(reduxProps.inhibitorStatus) || reduxProps.inhibitorStatus === InhibitorStatusEnum.Unknown;

        const isInhibitorEnabled = [InhibitorStatusEnum.Enabled, InhibitorStatusEnum.EnabledByIButton].some(
            (x) => x === reduxProps.inhibitorStatus
        );

        const isInhibitorDisabled = [
            InhibitorStatusEnum.Disabled,
            InhibitorStatusEnum.DisabledByIButton,
            InhibitorStatusEnum.DisabledByPin,
        ].some((x) => x === reduxProps.inhibitorStatus);

        const vehicleDisplayName = reduxProps.vehicle
            ? formatVehicleName(reduxProps.vehicle, displayPreferences.vehicleDisplayFormat)
            : '';

        const enableStartInhibitorDisabledReason = isInhibitorStatusUnknown
            ? 'enable-start-inhibitor-status-unknown-disabled-reason'
            : 'enable-start-inhibitor-disabled-reason';

        const disableStartInhibitorDisabledReason = isInhibitorStatusUnknown
            ? 'disable-start-inhibitor-status-unknown-disabled-reason'
            : 'disable-start-inhibitor-disabled-reason';

        const menuItems: ContextMenuItem[] = [];

        switch (assetType) {
            case AssetType.Vehicle: {
                if (reduxProps.canViewMap) {
                    menuItems.push({
                        disabled: !reduxProps.hasPosition,
                        hidden: menuType === AssetContextMenuType.Map,
                        icon: <GpsFixed />,
                        key: 'locate',
                        onClick: locateOnMap,
                        title: t('locate'),
                        to: generatePath(MONITORING_PATH_STRUCTURE, {
                            id: assetId,
                            perspective: MonitoringPerspective.VEHICLE,
                            viewMode: MonitoringViewMode.MAP,
                        }),
                    });
                }
                if (reduxProps.canViewHistory) {
                    menuItems.push({
                        disabled: !reduxProps.hasPosition,
                        icon: <HistoryIcon />,
                        key: 'history',
                        title: t('history'),
                        to: generatePath(MONITORING_PATH_STRUCTURE, {
                            id: assetId,
                            perspective: MonitoringPerspective.VEHICLE,
                            subpage: AssetSubpage.HISTORY,
                            viewMode,
                        }),
                    });
                }
                menuItems.push(
                    {
                        hidden: !reduxProps.allowMessaging,
                        icon: <Chat />,
                        key: 'open-conversation',
                        onClick: openConversation,
                        title: t('open-conversation'),
                    },
                    {
                        hidden: !reduxProps.allowDriverStatus,
                        icon: <DriverIcon />,
                        key: 'staff',
                        title: t('staff'),
                        to: generatePath(MONITORING_PATH_STRUCTURE, {
                            id: assetId,
                            perspective: MonitoringPerspective.VEHICLE,
                            subpage: AssetSubpage.DRIVER,
                            viewMode,
                        }),
                    },
                    {
                        disabled: !reduxProps.canConnectTrailer,
                        hidden: !reduxProps.allowTrailers || !!reduxProps.trailerConnection,
                        icon: <ConnectIcon />,
                        key: 'connect-trailer',
                        onClick: () => {
                            if (reduxProps.vehicle) {
                                setDialog(
                                    <ConnectTrailerDialog
                                        onClose={closeDialog}
                                        trailerConnection={reduxProps.trailerConnection}
                                        vehicle={reduxProps.vehicle}
                                    />
                                );
                            }
                        },
                        title: t('connect-trailer'),
                    },
                    {
                        disabled: !reduxProps.canDisconnectTrailer,
                        hidden: !reduxProps.allowTrailers || !reduxProps.trailerConnection,
                        icon: <DisconnectIcon />,
                        key: 'disconnect-trailer',
                        onClick: () => {
                            if (reduxProps.vehicle && reduxProps.trailerConnection) {
                                setDialog(
                                    <DisconnectTrailerFromVehicleDialog
                                        onClose={closeDialog}
                                        trailerConnection={reduxProps.trailerConnection}
                                        vehicle={reduxProps.vehicle}
                                    />
                                );
                            }
                        },
                        title: t('disconnect-trailer'),
                    },
                    {
                        disabled: isInhibitorStatusUnknown || isInhibitorEnabled,
                        disabledReason: t(enableStartInhibitorDisabledReason, { vehicleDisplayName }),
                        hidden: !reduxProps.canUseInhibitor || !reduxProps.vehicleSupportsInhibitorService,
                        icon: <InhibitorEnabledIcon />,
                        key: 'enable-start-inhibitor',
                        onClick: () => {
                            if (reduxProps.vehicle && reduxProps.canUseInhibitor) {
                                setDialog(
                                    <InhibitorActionDialog
                                        action={InhibitorAction.ENABLE}
                                        onClose={closeDialog}
                                        vehicle={reduxProps.vehicle}
                                    />
                                );
                            }
                        },
                        title: t('enable-start-inhibitor'),
                    },
                    {
                        disabled: isInhibitorStatusUnknown || isInhibitorDisabled,
                        disabledReason: t(disableStartInhibitorDisabledReason, { vehicleDisplayName }),
                        hidden: !reduxProps.canUseInhibitor || !reduxProps.vehicleSupportsInhibitorService,
                        icon: <InhibitorDisabledIcon />,
                        key: 'disable-start-inhibitor',
                        onClick: () => {
                            if (reduxProps.vehicle && reduxProps.canUseInhibitor) {
                                setDialog(
                                    <InhibitorActionDialog
                                        action={InhibitorAction.DISABLE}
                                        onClose={closeDialog}
                                        vehicle={reduxProps.vehicle}
                                    />
                                );
                            }
                        },
                        title: t('disable-start-inhibitor'),
                    },
                    {
                        hidden: !reduxProps.canUseInhibitor,
                        icon: <GeneratePinInhibitorIcon />,
                        key: 'start-inhibitor-pincode',
                        onClick: () => {
                            if (reduxProps.vehicle && reduxProps.canUseInhibitor) {
                                setDialog(
                                    <InhibitorActionDialog
                                        action={InhibitorAction.GETPIN}
                                        onClose={closeDialog}
                                        vehicle={reduxProps.vehicle}
                                    />
                                );
                            }
                        },
                        title: t('start-inhibitor-pincode'),
                    }
                );
                break;
            }

            case AssetType.Trailer: {
                if (reduxProps.canViewMap) {
                    menuItems.push({
                        disabled: !reduxProps.hasPosition,
                        hidden: menuType === AssetContextMenuType.Map,
                        icon: <GpsFixed />,
                        key: 'locate',
                        onClick: locateOnMap,
                        title: t('locate'),
                        to: generatePath(MONITORING_PATH_STRUCTURE, {
                            id: assetId,
                            perspective: MonitoringPerspective.TRAILER,
                            viewMode: MonitoringViewMode.MAP,
                        }),
                    });
                }

                if (reduxProps.canViewHistory) {
                    menuItems.push({
                        disabled: !reduxProps.hasPosition,
                        icon: <HistoryIcon />,
                        key: 'history',
                        title: t('history'),
                        to: generatePath(MONITORING_PATH_STRUCTURE, {
                            id: assetId,
                            perspective: MonitoringPerspective.TRAILER,
                            subpage: AssetSubpage.HISTORY,
                            viewMode,
                        }),
                    });
                }

                menuItems.push(
                    {
                        disabled: !reduxProps.canConnectVehicle,
                        hidden: !!reduxProps.vehicleConnection,
                        icon: <ConnectIcon />,
                        key: 'connect-vehicle',
                        onClick: () => {
                            if (reduxProps.trailer) {
                                setDialog(
                                    <ConnectVehicleDialog
                                        onClose={closeDialog}
                                        trailer={reduxProps.trailer}
                                        vehicleConnection={reduxProps.vehicleConnection}
                                    />
                                );
                            }
                        },
                        title: t('connect-vehicle'),
                    },
                    {
                        disabled: !reduxProps.canDisconnectVehicle,
                        hidden: !reduxProps.vehicleConnection,
                        icon: <DisconnectIcon />,
                        key: 'disconnect-vehicle',
                        onClick: () => {
                            if (reduxProps.trailer && reduxProps.vehicleConnection) {
                                setDialog(
                                    <DisconnectVehicleFromTrailerDialog
                                        onClose={closeDialog}
                                        trailer={reduxProps.trailer}
                                        vehicleConnection={reduxProps.vehicleConnection}
                                    />
                                );
                            }
                        },
                        title: t('disconnect-vehicle'),
                    }
                );
                break;
            }
            case AssetType.Container: {
                if (reduxProps.canViewMap) {
                    menuItems.push({
                        disabled: !reduxProps.hasPosition,
                        hidden: menuType === AssetContextMenuType.Map,
                        icon: <GpsFixed />,
                        key: 'locate',
                        onClick: locateOnMap,
                        title: t('locate'),
                        to: generatePath(MONITORING_PATH_STRUCTURE, {
                            id: assetId,
                            perspective: MonitoringPerspective.CONTAINER,
                            viewMode: MonitoringViewMode.MAP,
                        }),
                    });
                }
                if (reduxProps.canViewHistory) {
                    menuItems.push({
                        disabled: !reduxProps.hasPosition,
                        icon: <HistoryIcon />,
                        key: 'history',
                        title: t('history'),
                        to: generatePath(MONITORING_PATH_STRUCTURE, {
                            id: assetId,
                            perspective: MonitoringPerspective.CONTAINER,
                            subpage: AssetSubpage.HISTORY,
                            viewMode,
                        }),
                    });
                }
                break;
            }
            default:
                throw new Error('unsupported asset type');
        }

        menuItems.push({
            disabled: !reduxProps.hasPosition,
            icon: <CompassIcon />,
            key: 'copy-coordinates',
            onClick: copyCoordinates,
            title: t('copy-coordinates'),
        });
        if (reduxProps.canUseRouteCalculation) {
            menuItems.push({
                disabled: !reduxProps.hasPosition,
                icon: <Route />,
                key: 'calculate-route',
                title: 'Calculate route',
                to: `${SceneRoutes.ROUTE_CALCULATION}?${new URLSearchParams({
                    lat: String(reduxProps?.assetPosition?.latitude),
                    lng: String(reduxProps?.assetPosition?.longitude),
                    ...(reduxProps?.vehicle?.vehicleTypeId
                        ? { vehicleTypeId: String(reduxProps?.vehicle?.vehicleTypeId) }
                        : {}),
                }).toString()}`,
            });
        }

        return menuItems;
    };

    return (
        <>
            <ContextMenu dataId="asset-context-menu" menuItems={getMenuItems()} onClose={onClose} position={position} />
            {dialog}
        </>
    );
};
