import type { NumericDictionary } from '~/libs/utility';
import { isUndefined } from '~/libs/utility';
import type { Container, DriverStatus, Trailer, Vehicle } from '~/services/ApiClient';
import { AssetType } from '~/services/ApiClient';
import { trailerResolver, vehicleResolver } from '~/services/ModelResolvers';

import { MonitoringPerspective } from './consts';
import type {
    BuildAssetEntriesProps,
    BuildContainerEntriesProps,
    BuildContainerEntryProps,
    BuildDriverEntriesProps,
    BuildDriverEntryProps,
    BuildSelectedAssetEntryProps,
    BuildSelectedContainerEntryProps,
    BuildSelectedDriverEntryProps,
    BuildSelectedTrailerEntryProps,
    BuildSelectedVehicleEntryProps,
    BuildTrailerEntriesProps,
    BuildTrailerEntryProps,
    BuildVehicleEntriesProps,
    BuildVehicleEntryProps,
    MonitoringAssetEntry,
    MonitoringAssetType,
    MonitoringContainerEntry,
    MonitoringDriverEntry,
    MonitoringTrailerEntry,
    MonitoringVehicleEntry,
} from './models';
import {
    containerResolver,
    containerStatusResolver,
    driverStatusResolver,
    inhibitorStatusResolver,
    routeStatusResolver,
    trailerConnectionResolver,
    trailerStatusResolver,
    vehicleConnectionResolver,
    vehicleDriverHoursStatusResolver,
} from './resolvers';
import { filterAssets } from './utils';

const buildMonitoringVehicleEntry = (props: BuildVehicleEntryProps): MonitoringVehicleEntry => {
    const {
        canViewTrailers,
        compartmentStatus,
        devices,
        deviceStatuses,
        deviceTypes,
        doorStatus,
        driverActivityTypes,
        driverSubActivityTypes,
        hookedStatus,
        inhibitorStatuses,
        reeferAlarm,
        reeferManufacturers,
        reeferOperationMode,
        reeferPowerMode,
        reeferSpeed,
        reeferStatus,
        routeStatusTypes,
        trailerBatteryStatus,
        trailerEventType,
        trailerManufacturers,
        trailersHash,
        trailersStatus,
        vehicle,
        vehicleCategories,
        vehicleConnections,
        vehicleDriverHoursStatus,
        vehicleInhibitorStatuses,
        vehicleRouteStatuses,
        vehicleStatuses,
        vehicleTypes,
    } = props;
    const { device } = vehicle;
    const deviceForCurrentVehicle = devices.array.find((x) => x.msisdn === device?.msisdn);
    const deviceStatusForCurrentVehicle = deviceForCurrentVehicle
        ? deviceStatuses[deviceForCurrentVehicle.id]
        : undefined;

    const vehicleDriverHourStatus = vehicleDriverHoursStatus[vehicle.id];
    const trailerConnection = vehicleConnections[vehicle.id]?.trailerConnection;
    const routeStatus = vehicleRouteStatuses[vehicle.id];
    const inhibitorStatus = vehicleInhibitorStatuses[vehicle.id];

    return {
        canManuallyConnectTrailers: vehicleConnections[vehicle.id]?.canManuallyConnectTrailers,
        canManuallyDisconnect: vehicleConnections[vehicle.id]?.canManuallyDisconnect,
        deviceStatus: deviceStatusForCurrentVehicle,
        inhibitorStatus: inhibitorStatus && inhibitorStatusResolver(inhibitorStatus, inhibitorStatuses),
        routeStatus: routeStatus && routeStatusResolver(routeStatus, routeStatusTypes),
        trailerConnection:
            trailerConnection &&
            trailerConnectionResolver({
                compartmentStatus,
                doorStatus,
                hookedStatus,
                reeferAlarm,
                reeferManufacturers,
                reeferOperationMode,
                reeferPowerMode,
                reeferSpeed,
                reeferStatus,
                trailer: canViewTrailers ? trailersHash[trailerConnection.trailer.id] : undefined,
                trailerBatteryStatus,
                trailerConnection,
                trailerEventType,
                trailerManufacturers,
                trailerStatus: canViewTrailers ? trailersStatus[trailerConnection.trailer.id] : undefined,
            }),
        vehicle: vehicleResolver({ categories: vehicleCategories, deviceTypes, vehicle, vehicleTypes }),
        vehicleDriverHoursStatus: vehicleDriverHourStatus
            ? vehicleDriverHoursStatusResolver(vehicleDriverHourStatus, driverActivityTypes, driverSubActivityTypes)
            : undefined,
        vehicleStatus: vehicleStatuses[vehicle.id],
    };
};

const buildMonitoringVehicleEntries = (props: BuildVehicleEntriesProps): MonitoringVehicleEntry[] => {
    const { vehicles, ...restProps } = props;

    return vehicles.map((vehicle: Vehicle) => buildMonitoringVehicleEntry({ vehicle, ...restProps }));
};

const buildMonitoringTrailerEntry = (props: BuildTrailerEntryProps): MonitoringTrailerEntry => {
    const {
        compartmentStatus,
        deviceTypes,
        doorStatus,
        driverActivityTypes,
        driverSubActivityTypes,
        hookedStatus,
        reeferAlarm,
        reeferManufacturers,
        reeferOperationMode,
        reeferPowerMode,
        reeferSpeed,
        reeferStatus,
        trailer,
        trailerBatteryStatus,
        trailerEventType,
        trailerManufacturers,
        trailersStatus,
        vehicleCategories,
        vehicleConnection,
        vehicleDriverHoursStatus,
        vehiclesHash,
        vehicleTypes,
    } = props;

    return {
        status:
            trailersStatus[trailer.id] &&
            trailerStatusResolver({
                compartmentStatus,
                doorStatus,
                hookedStatus,
                reeferAlarm,
                reeferOperationMode,
                reeferPowerMode,
                reeferSpeed,
                reeferStatus,
                trailerBatteryStatus,
                trailerEventType,
                trailerStatus: trailersStatus[trailer.id],
            }),
        trailer: trailerResolver(trailer, trailerManufacturers, reeferManufacturers),
        vehicleConnection:
            vehicleConnection &&
            vehicleConnectionResolver({
                deviceTypes,
                driverActivityTypes,
                driverSubActivityTypes,
                vehicleCategories,
                vehicleConnection,
                vehicleDriverHoursStatus,
                vehiclesHash,
                vehicleTypes,
            }),
    };
};

const buildMonitoringTrailerEntries = (props: BuildTrailerEntriesProps): MonitoringTrailerEntry[] => {
    const { trailers, vehicleConnectionsByTrailer, ...restProps } = props;

    return trailers.map((trailer: Trailer) =>
        buildMonitoringTrailerEntry({
            trailer,
            vehicleConnection: vehicleConnectionsByTrailer[trailer.id],
            ...restProps,
        })
    );
};

const buildMonitoringContainerEntry = (props: BuildContainerEntryProps): MonitoringContainerEntry => {
    const { container, containerEventType, containerStatus, containerTemperatureType } = props;

    const status =
        containerStatus[container.id] &&
        containerStatusResolver(containerStatus[container.id], containerEventType, containerTemperatureType);

    return {
        container: containerResolver(container),
        status,
    };
};

const buildMonitoringContainerEntries = (props: BuildContainerEntriesProps): MonitoringContainerEntry[] => {
    const { containers } = props;

    return containers.map((container: Container) => buildMonitoringContainerEntry({ container, ...props }));
};

const buildMonitoringDriverEntry = (props: BuildDriverEntryProps): MonitoringDriverEntry => {
    const {
        driverActivityTypes,
        driversHash,
        driverStatus,
        driverSubActivityTypes,
        vehicleCategories,
        vehiclesHash,
        vehicleStatuses,
        vehicleTypes,
    } = props;

    return {
        driver: driverStatusResolver({
            driverActivityTypes,
            driversHash,
            driverStatus,
            driverSubActivityTypes,
            vehicleCategories,
            vehiclesHash,
            vehicleStatuses,
            vehicleTypes,
        }),
        status: driverStatus,
    };
};

const buildMonitoringDriverEntries = (props: BuildDriverEntriesProps): MonitoringDriverEntry[] => {
    const { driverStatuses } = props;

    return driverStatuses.map((driverStatus: DriverStatus) => buildMonitoringDriverEntry({ driverStatus, ...props }));
};

const buildMonitoringAssetEntries = (props: BuildAssetEntriesProps): MonitoringAssetEntry[] => {
    const { commonProps, perspective, selectedAssets } = props;

    switch (perspective) {
        case MonitoringPerspective.VEHICLE: {
            const { vehicles, ...restVehicleProps } = props.vehicleProps;

            const filteredVehicles = filterAssets({
                assets: vehicles,
                assetType: AssetType.Vehicle,
                selectedAssets,
            });

            return buildMonitoringVehicleEntries({
                vehicles: filteredVehicles,
                ...restVehicleProps,
                ...commonProps,
            });
        }
        case MonitoringPerspective.TRAILER: {
            const { trailers, ...restTrailerProps } = props.trailerProps;

            const filteredTrailers = filterAssets({ assets: trailers, assetType: AssetType.Trailer, selectedAssets });

            return buildMonitoringTrailerEntries({
                trailers: filteredTrailers,
                ...restTrailerProps,
                ...commonProps,
            });
        }
        case MonitoringPerspective.CONTAINER: {
            const { containers, ...restContainerProps } = props.containerProps;

            const filteredContainers = filterAssets({
                assets: containers,
                assetType: AssetType.Container,
                selectedAssets,
            });

            return buildMonitoringContainerEntries({
                containers: filteredContainers,
                ...restContainerProps,
            });
        }
        case MonitoringPerspective.DRIVER: {
            const filteredDrivers = filterAssets({
                assets: props.driverProps.driverStatuses,
                assetType: AssetType.Driver,
                selectedAssets,
            });
            return buildMonitoringDriverEntries({
                ...props.driverProps,
                driverStatuses: filteredDrivers,
                vehicleStatuses: props.vehicleProps.vehicleStatuses,
                ...commonProps,
            });
        }
        default:
            throw Error(`Unknown perspective: ${perspective}`);
    }
};

const buildSelectedContainerEntry = (props: BuildSelectedContainerEntryProps): MonitoringContainerEntry | undefined => {
    const { containersHash, selectedContainerId, ...restProps } = props;

    if (isUndefined(selectedContainerId)) {
        return;
    }

    const container = containersHash[selectedContainerId];

    return container && buildMonitoringContainerEntry({ container, ...restProps });
};

const buildSelectedTrailerEntry = (props: BuildSelectedTrailerEntryProps): MonitoringTrailerEntry | undefined => {
    const { selectedTrailerId, trailersHash, vehicleConnectionsByTrailer, ...restProps } = props;

    if (isUndefined(selectedTrailerId)) {
        return;
    }

    const trailer = trailersHash[selectedTrailerId];
    const vehicleConnection = vehicleConnectionsByTrailer[selectedTrailerId];

    return trailer && buildMonitoringTrailerEntry({ trailer, vehicleConnection, ...restProps });
};

const buildSelectedVehicleEntry = (props: BuildSelectedVehicleEntryProps): MonitoringVehicleEntry | undefined => {
    const { selectedVehicleId, vehiclesHash, ...rest } = props;

    if (isUndefined(selectedVehicleId)) {
        return;
    }

    const vehicle = vehiclesHash[selectedVehicleId];

    return vehicle && buildMonitoringVehicleEntry({ vehicle, ...rest });
};

const buildSelectedDriverEntry = (props: BuildSelectedDriverEntryProps): MonitoringDriverEntry | undefined => {
    const { driverStatusesHash, selectedDriverId, ...rest } = props;

    if (isUndefined(selectedDriverId)) {
        return;
    }

    const driverStatus = driverStatusesHash[selectedDriverId];

    return driverStatus && buildMonitoringDriverEntry({ driverStatus, ...rest });
};

const buildSelectedAssetEntry = <T extends MonitoringAssetType>(
    props: BuildSelectedAssetEntryProps<T>
): MonitoringAssetEntry | undefined => {
    const {
        assetsHash,
        commonProps,
        containerProps,
        driverProps,
        perspective,
        selectedAssetId,
        trailerProps,
        vehicleProps,
    } = props;

    if (!selectedAssetId) {
        return;
    }

    switch (perspective) {
        case MonitoringPerspective.CONTAINER:
            return buildSelectedContainerEntry({
                containersHash: assetsHash as NumericDictionary<Container>,
                selectedContainerId: selectedAssetId,
                ...containerProps,
            });
        case MonitoringPerspective.DRIVER:
            return buildSelectedDriverEntry({
                driverStatusesHash: assetsHash as NumericDictionary<DriverStatus>,
                selectedDriverId: selectedAssetId,
                vehicleStatuses: vehicleProps.vehicleStatuses,
                ...driverProps,
                ...commonProps,
            });
        case MonitoringPerspective.TRAILER:
            return buildSelectedTrailerEntry({
                selectedTrailerId: selectedAssetId,
                trailersHash: assetsHash as NumericDictionary<Trailer>,
                ...trailerProps,
                ...commonProps,
            });
        case MonitoringPerspective.VEHICLE:
            return buildSelectedVehicleEntry({
                selectedVehicleId: selectedAssetId,
                vehiclesHash: assetsHash as NumericDictionary<Vehicle>,
                ...vehicleProps,
                ...commonProps,
            });
        default:
    }
};

export {
    buildMonitoringAssetEntries,
    buildMonitoringContainerEntries,
    buildMonitoringTrailerEntries,
    buildMonitoringVehicleEntries,
    buildSelectedAssetEntry,
    buildSelectedContainerEntry,
    buildSelectedTrailerEntry,
    buildSelectedVehicleEntry,
};
