import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withDisplayPreferences } from '~/components/DisplayPreferences';
import { withTranslation } from '~/components/LanguageSelector';

import { EbsSectionContentComponent } from './component';
import type { EbsSectionContentInnerProps, EbsSectionContentProps } from './model';
import { EbsSectionContentStyleRules } from './styles';

export const EbsSectionContent = compose<EbsSectionContentInnerProps, EbsSectionContentProps>(
    setDisplayName('EbsSectionContent'),
    withDisplayPreferences(),
    withTranslation(),
    withStyles(EbsSectionContentStyleRules)
)(EbsSectionContentComponent);
