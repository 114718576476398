import { ActionButtonGroup } from '@fv/components';
import type { ActionItem } from '@fv/components';
import GpsFixed from '@mui/icons-material/GpsFixed';
import Route from '@mui/icons-material/Route';
import type { FC } from 'react';
import { useMemo } from 'react';
import { generatePath } from 'react-router';

import { CompassIcon, HistoryIcon } from '~/components/Icons';
import { useTranslation } from '~/components/LanguageSelector';
import type { MonitoringPerspective } from '~/data/monitoring';
import { AssetSubpage, MonitoringViewMode } from '~/data/monitoring';
import { isUndefined } from '~/libs/utility';
import { SceneRoutes } from '~/routes';
import type { Position } from '~/services/ApiClient';
import { copyCoordinatesToClipboard } from '~/services/Clipboard';

import { MONITORING_PATH_STRUCTURE } from '../../../../consts';

import { RootContainer } from './styles';

export interface LocationSectionActionButtonsProps {
    assetId: number;
    onLocate: () => void;
    perspective: MonitoringPerspective;
    position?: Position;
    showHistoryButton: boolean;
    vehicleTypeId?: number;
    viewMode: MonitoringViewMode;
}

export interface StateProps {
    canUseRouteCalculation: boolean;
    canViewHistory: boolean;
    canViewMap: boolean;
}

export interface LocationSectionActionButtonsInnerProps extends LocationSectionActionButtonsProps, StateProps {}

export const LocationSectionActionButtonsComponent: FC<LocationSectionActionButtonsInnerProps> = (props) => {
    const {
        assetId,
        canUseRouteCalculation,
        canViewHistory,
        canViewMap,
        onLocate,
        perspective,
        position,
        showHistoryButton,
        vehicleTypeId,
        viewMode,
    } = props;

    const { t } = useTranslation();

    const actionItems = useMemo(
        (): ActionItem[] => [
            ...(canViewMap
                ? [
                      {
                          icon: <GpsFixed />,
                          key: 'locate',
                          onClick: onLocate,
                          title: t('locate'),
                          to: generatePath(MONITORING_PATH_STRUCTURE, {
                              id: assetId,
                              perspective,
                              viewMode: MonitoringViewMode.MAP,
                          }),
                      },
                  ]
                : []),
            ...(showHistoryButton && canViewHistory
                ? [
                      {
                          icon: <HistoryIcon />,
                          key: 'show-history',
                          title: t('history'),
                          to: generatePath(MONITORING_PATH_STRUCTURE, {
                              id: assetId,
                              perspective,
                              subpage: AssetSubpage.HISTORY,
                              viewMode,
                          }),
                      },
                  ]
                : []),
            ...(canUseRouteCalculation
                ? [
                      {
                          disabled: isUndefined(position),
                          icon: <Route />,
                          key: 'calculate-route',
                          title: t('route-calculation.calculate-route'),
                          to: `${SceneRoutes.ROUTE_CALCULATION}?${new URLSearchParams({
                              lat: String(position?.latitude),
                              lng: String(position?.longitude),
                              ...(vehicleTypeId ? { vehicleTypeId: String(vehicleTypeId) } : {}),
                          }).toString()}`,
                      },
                  ]
                : []),
            {
                disabled: isUndefined(position),
                icon: <CompassIcon />,
                key: 'copy-coordinates',
                onClick: () => {
                    if (!isUndefined(position)) {
                        copyCoordinatesToClipboard(position);
                    }
                },
                title: t('copy-coordinates'),
            },
        ],
        [
            assetId,
            canUseRouteCalculation,
            canViewHistory,
            canViewMap,
            onLocate,
            perspective,
            position,
            showHistoryButton,
            t,
            vehicleTypeId,
            viewMode,
        ]
    );

    return (
        <RootContainer>
            <ActionButtonGroup items={actionItems} />
        </RootContainer>
    );
};
