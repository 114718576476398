import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { SettingsKey, settingDataSelector } from '~/components/EnsureSettings';
import { withTranslation } from '~/components/LanguageSelector';

import type { LocationSectionActionButtonsInnerProps, LocationSectionActionButtonsProps } from './component';
import { LocationSectionActionButtonsComponent } from './component';
import { mapStateToProps } from './redux';

export const LocationSectionActionButtons = compose<
    LocationSectionActionButtonsInnerProps,
    LocationSectionActionButtonsProps
>(
    setDisplayName('LocationSectionActionButtons'),
    withTranslation(),
    connect(createSelector(settingDataSelector(SettingsKey.SECURABLES), mapStateToProps))
)(LocationSectionActionButtonsComponent);
