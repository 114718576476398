import { createAction } from 'redux-actions';

import { ApiClient } from '~/services/ApiClient';

import { ACTIONS_KEYS } from './actionTypes';

const getAdministrationLandmarks = createAction(ACTIONS_KEYS.LIST, () => ApiClient.getAdminLandmarks());

const getLandmarksAssignments = createAction(ACTIONS_KEYS.GET_LANDMARK_WITH_ASSIGNMENTS, (landmarkId: number) =>
    ApiClient.getLandmark(landmarkId)
);

export { getAdministrationLandmarks, getLandmarksAssignments };
