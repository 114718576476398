import { Notification } from '@fv/components/Notification';
import { Suspense, lazy } from 'react';
import { compose } from 'react-recompose';
import { Route, Switch } from 'react-router-dom';

import { ActiveCountAutoUpdate } from '~/components/ActiveCountAutoUpdate';
import { InactivityMonitor, withScreenAnalytics } from '~/components/Analytics';
import { protectedComponentHoc } from '~/components/AuthenticationData';
import { EnsureAuthorization } from '~/components/EnsureAuthorization';
import { SettingsKey, ensureSettings } from '~/components/EnsureSettings';
import { ensureTheme } from '~/components/EnsureTheme';
import { IntroCarousel } from '~/components/IntroCarousel';
import { MapContextProviderWrapper } from '~/components/MapContextProviderWrapper';
import { LandingPage } from '~/components/Navigation';
import { NotAuthorizedPageSnackbar } from '~/components/NotAuthorizedPageSnackbar';
import { NotFoundPageSnackbar } from '~/components/NotFoundPageSnackbar';
import { SaveUserPreferencesStatusMonitor } from '~/components/SaveUserPreferencesMonitor';
import { SignalRConnectionMonitor } from '~/components/SignalRConnectionMonitor';
import { SynchronizationClock } from '~/components/SynchronizationClock';
import { HandleUserPreferencesStateUpdate, LoadUserPreferencesStatusMonitor } from '~/components/UserPreferences';
import { AssetSubpage, MonitoringPerspective, MonitoringViewMode } from '~/data/monitoring';
import {
    ADMIN_COMPANY_CARD_PATHS,
    ADMIN_DEVICES_PATHS,
    ADMIN_VEHICLE_TYPES_PATHS,
    ALERT_DEFINITIONS_PATHS,
    RTDS_SCHEDULES_PATHS,
    SceneRoutes,
} from '~/routes';
import { AlertViewerScene } from '~/scenes/Alerts';
import { CustomerCareLogin, EndImpersonation, Logout, SingleSignOn, UserLogin } from '~/scenes/Authentication';
import CompanyCardsAdministration from '~/scenes/CompanyCardAdministration';
import { History, HistoryEventType } from '~/scenes/History';
import { Impersonation } from '~/scenes/Impersonation';
import { Monitoring } from '~/scenes/Monitoring';
import { ResetPassword } from '~/scenes/ResetPassword';
import { RtdsSchedules } from '~/scenes/RtdsSchedules';
import { RtdsSessions } from '~/scenes/RtdsSessions';
import { TachoFiles } from '~/scenes/TachoFiles';
import TachoLincStatus from '~/scenes/TachoLincStatus';
import { VehicleAdministration } from '~/scenes/VehicleAdministration';
import { VehicleTypesAdministration } from '~/scenes/VehicleTypesAdministration';
import { AssetType } from '~/services/ApiClient';
import { saveUserPreferencesService } from '~/services/SaveUserPreferences';
import { NotificationsClient } from '~/services/SignalR';

import { GeozoneNotifications } from './components/GeozoneNotifications';
import { SceneLoader } from './components/SceneLoader';
import { ErrorBoundary } from './ErrorBoundary';

// Unprotected routes
const DecoratedSingleSignOn = withScreenAnalytics('singlesignon')(SingleSignOn);
const DecoratedUserLogin = withScreenAnalytics('userlogin')(UserLogin);
const DecoratedCustomerCareLogin = withScreenAnalytics('customercarelogin')(CustomerCareLogin);
const DecoratedLogout = withScreenAnalytics('logout')(Logout);
const DecoratedEndImpersonation = withScreenAnalytics('endimpersonation')(EndImpersonation);
const FeatureFlags = lazy(() => import('~/scenes/FeatureFlags'));

// Routes to be protected
const AlertDefinitions = lazy(() => import('~/scenes/AlertDefinitions'));
const Communication = lazy(() => import('~/scenes/Communication'));
const DeviceAdministration = lazy(() => import('./scenes/DeviceAdministration'));
const DriverActivitiesReport = lazy(() => import('~/scenes/DriverActivitiesReport'));
const DriverAdministration = lazy(() => import('./scenes/DriverAdministration'));
const GeozoneAdministration = lazy(() => import('~/scenes/GeozoneAdministration'));
const LandmarksAdministration = lazy(() => import('~/scenes/LandmarksAdministration'));
const GroupAdministration = lazy(() => import('./scenes/GroupAdministration'));
const TrailerAdministration = lazy(() => import('./scenes/TrailerAdministration'));
const TripReport = lazy(() => import('~/scenes/TripReport'));
const UserAdministration = lazy(() => import('~/scenes/UserAdministration'));
const UserRolesPermissions = lazy(() => import('~/scenes/UserRolesPermissions'));
const ReportSettings = lazy(() => import('~/scenes/ReportSettings'));
const UserSettings = lazy(() => import('~/scenes/UserSettings'));
const CustomerSettings = lazy(() => import('~/scenes/CustomerSettings'));
const AssetNearBy = lazy(() => import('~/scenes/AssetNearBy'));
const RouteCalculation = lazy(() => import('~/scenes/RouteCalculation'));

const Routes = () => {
    const monitoringViewModePattern = `${MonitoringViewMode.LIST}|${MonitoringViewMode.MAP}`;
    const monitoringPerspectivePattern = `${MonitoringPerspective.VEHICLE}|${MonitoringPerspective.TRAILER}|${MonitoringPerspective.CONTAINER}|${MonitoringPerspective.DRIVER}`;
    const monitoringSubpagePattern = `${AssetSubpage.DRIVER}|${AssetSubpage.CODRIVER}|${AssetSubpage.HISTORY}`;
    const monitoringPaths = [
        SceneRoutes.MONITORING,
        `${SceneRoutes.MONITORING}/:viewMode(${monitoringViewModePattern})`,
        `${SceneRoutes.MONITORING}/:viewMode(${monitoringViewModePattern})/:perspective(${monitoringPerspectivePattern})`,
        `${SceneRoutes.MONITORING}/:viewMode(${monitoringViewModePattern})/:perspective(${monitoringPerspectivePattern})/:selectedAssetId([0-9]+)`,
        `${SceneRoutes.MONITORING}/:viewMode(${monitoringViewModePattern})/:perspective(${monitoringPerspectivePattern})/:selectedAssetId([0-9]+)/:subpage(${monitoringSubpagePattern})`,
    ];

    const historyEventTypesPattern = `${HistoryEventType.VEHICLE}|${HistoryEventType.TRAILER}|${HistoryEventType.DRIVER}`;
    const historyAssetTypePattern = `${AssetType.Vehicle}|${AssetType.Trailer}|${AssetType.Driver}`;
    const historyPaths = [
        SceneRoutes.HISTORY,
        `${SceneRoutes.HISTORY}/:eventType(${historyEventTypesPattern})`,
        `${SceneRoutes.HISTORY}/:eventType(${historyEventTypesPattern})/:selectedAssetType(${historyAssetTypePattern})/:selectedAssetId([0-9]+)`,
    ];

    return (
        <MapContextProviderWrapper>
            <Switch>
                <Route component={Monitoring} exact path={monitoringPaths} />
                <Route component={History} exact path={historyPaths} />
                <Route component={Communication} exact path={SceneRoutes.COMMUNICATION} />
                <Route exact path={SceneRoutes.ROUTE_CALCULATION}>
                    <EnsureAuthorization securablesPredicate={(securables) => securables.routing.routes.isAllowed}>
                        <RouteCalculation />
                    </EnsureAuthorization>
                </Route>
                <Route component={TachoFiles} exact path={SceneRoutes.TACHOFILES} />
                <Route component={RtdsSessions} exact path={SceneRoutes.RTDSSESSIONS} />
                <Route component={TachoLincStatus} exact path={SceneRoutes.TACHOLINCSTATUS} />
                <Route component={RtdsSchedules} exact path={RTDS_SCHEDULES_PATHS} />
                <Route component={Impersonation} exact path={SceneRoutes.IMPERSONATION} />
                <Route path={SceneRoutes.ADMIN_VEHICLES}>
                    <EnsureAuthorization
                        securablesPredicate={(securables) => securables.assetAdministration.vehicles.isAllowed}
                    >
                        <VehicleAdministration />
                    </EnsureAuthorization>
                </Route>
                <Route path={SceneRoutes.ADMIN_DRIVERS}>
                    <EnsureAuthorization
                        securablesPredicate={(securables) => securables.assetAdministration.drivers.isAllowed}
                    >
                        <DriverAdministration />
                    </EnsureAuthorization>
                </Route>
                <Route component={VehicleTypesAdministration} exact path={ADMIN_VEHICLE_TYPES_PATHS} />
                <Route component={CompanyCardsAdministration} exact path={ADMIN_COMPANY_CARD_PATHS} />
                <Route component={DeviceAdministration} exact path={ADMIN_DEVICES_PATHS} />
                <Route path={SceneRoutes.ADMIN_TRAILERS}>
                    <EnsureAuthorization
                        securablesPredicate={(securables) => securables.assetAdministration.trailers.isAllowed}
                    >
                        <TrailerAdministration />
                    </EnsureAuthorization>
                </Route>
                <Route exact path={ALERT_DEFINITIONS_PATHS}>
                    <EnsureAuthorization
                        securablesPredicate={(securables) =>
                            securables.formsAdministration.alertManagerDefinitions.isAllowed
                        }
                    >
                        <AlertDefinitions />
                    </EnsureAuthorization>
                </Route>
                <Route path={SceneRoutes.ALERTS}>
                    <EnsureAuthorization securablesPredicate={(securables) => securables.alertManager.isAllowed}>
                        <AlertViewerScene />
                    </EnsureAuthorization>
                </Route>
                <Route path={SceneRoutes.USER_ROLES_PERMISSIONS}>
                    <EnsureAuthorization
                        securablesPredicate={(securables) =>
                            securables.securityAdministration.userRolesAndPermissions.isAllowed
                        }
                    >
                        <UserRolesPermissions />
                    </EnsureAuthorization>
                </Route>
                <Route component={GroupAdministration} path={SceneRoutes.ADMIN_GROUPS} />
                <Route component={TripReport} path={SceneRoutes.TRIP_REPORT} />
                <Route component={DriverActivitiesReport} path={SceneRoutes.DRIVER_ACTIVITIES_REPORT} />
                <Route path={SceneRoutes.ASSET_NEAR_BY}>
                    <EnsureAuthorization securablesPredicate={(securables) => securables.services.assetsNearby}>
                        <AssetNearBy />
                    </EnsureAuthorization>
                </Route>
                <Route path={SceneRoutes.ADMIN_USERS}>
                    <EnsureAuthorization
                        securablesPredicate={(securables) => securables.securityAdministration.users.isAllowed}
                    >
                        <UserAdministration />
                    </EnsureAuthorization>
                </Route>
                <Route path={SceneRoutes.GEOZONE_ADMINISTRATION}>
                    <EnsureAuthorization
                        securablesPredicate={(securables) =>
                            securables.services.geozones && securables.geozonesAdministration.isAllowed
                        }
                    >
                        <GeozoneAdministration />
                    </EnsureAuthorization>
                </Route>
                <Route path={SceneRoutes.LANDMARKS_ADMINISTRATION}>
                    <EnsureAuthorization
                        securablesPredicate={(securables) =>
                            securables.planning.landmarks.assignLandmarks &&
                            securables.planning.landmarks.defineLandmarks
                        }
                    >
                        <LandmarksAdministration />
                    </EnsureAuthorization>
                </Route>

                <Route path={SceneRoutes.SETTINGS_USER}>
                    <UserSettings />
                </Route>

                <Route path={SceneRoutes.SETTINGS_CUSTOMER}>
                    <EnsureAuthorization
                        securablesPredicate={(securables) => securables.customerSettings.manageSettings}
                    >
                        <CustomerSettings />
                    </EnsureAuthorization>
                </Route>

                <Route path={SceneRoutes.SETTINGS_REPORTS}>
                    <EnsureAuthorization>
                        <ReportSettings />
                    </EnsureAuthorization>
                </Route>

                <Route component={NotFoundPageSnackbar} path="*" />
            </Switch>
            <Notification />
            <IntroCarousel />
            <ActiveCountAutoUpdate />
        </MapContextProviderWrapper>
    );
};

const ProtectedRoutes = compose(protectedComponentHoc, ensureTheme(), ensureSettings([SettingsKey.SECURABLES]))(Routes);

export const App = () => {
    const resetPasswordPaths = ['/reset-password', `/reset-password/:token=[A-Za-z0-9\\-]+`];
    return (
        <ErrorBoundary>
            <SynchronizationClock />

            <Suspense fallback={<SceneLoader />}>
                <Switch>
                    <Route component={LandingPage} exact path="/" />

                    <Route component={DecoratedUserLogin} path="/login" />
                    <Route component={DecoratedCustomerCareLogin} path="/customer-care" />
                    <Route component={DecoratedLogout} path="/logout" />
                    <Route component={DecoratedEndImpersonation} path="/end-impersonation" />
                    <Route component={DecoratedSingleSignOn} path="/single-sign-on" />
                    <Route component={FeatureFlags} path="/feature-flags" />
                    <Route component={NotAuthorizedPageSnackbar} path="/unauthorized" />
                    <Route component={ResetPassword} path={resetPasswordPaths} />

                    <Route component={ProtectedRoutes} />
                </Switch>
            </Suspense>

            <SignalRConnectionMonitor signalRConnection={NotificationsClient} />
            <HandleUserPreferencesStateUpdate />
            <LoadUserPreferencesStatusMonitor />
            <SaveUserPreferencesStatusMonitor savePreferencesService={saveUserPreferencesService} />
            <InactivityMonitor />
            <GeozoneNotifications />
        </ErrorBoundary>
    );
};
