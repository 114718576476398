export enum ColumnName {
    ADDRESS = 'address',
    ALTITUDE = 'altitude',
    AVAILABLEBIWEEKLYDRIVINGTIME = 'available-bi-weekly-driving-time',
    AVAILABLEDAILYDRIVINGTIME = 'available-daily-driving-time',
    AVAILABLEWEEKLYDRIVINGTIME = 'available-weekly-driving-time',
    AVERAGEWEEKLYLABOR = 'average-weekly-labor',
    AXLELOAD = 'axle-load',
    BATTERYLEVEL = 'battery-level',
    CITY = 'city',
    CODRIVERACTIVITY = 'co-driver-activity',
    CODRIVERAVAILABLEBIWEEKLYDRIVINGTIME = 'co-driver-available-bi-weekly-driving-time',
    CODRIVERAVAILABLEDAILYDRIVINGTIME = 'co-driver-available-daily-driving-time',
    CODRIVERAVAILABLEWEEKLYDRIVINGTIME = 'co-driver-available-weekly-driving-time',
    CODRIVERAVERAGEWEEKLYLABOR = 'co-driver-average-weekly-labor',
    CODRIVERCONTINUOUSDRIVING = 'co-driver-continuous-driving',
    CODRIVERCONTINUOUSLABORLONG = 'co-driver-continuous-labor-long',
    CODRIVERCONTINUOUSLABORSHORT = 'co-driver-continuous-labor-short',
    CODRIVERCONTINUOUSWORKAVAILABLE = 'co-driver-continuous-work-available',
    CODRIVERDAILYDRIVING = 'co-driver-daily-driving',
    CODRIVERDAILYDUTY = 'co-driver-daily-duty',
    CODRIVERDAILYRESTING = 'co-driver-daily-resting',
    CODRIVERDRIVEBREAK = 'co-driver-drive-break',
    CODRIVERDURATION = 'co-driver-duration',
    CODRIVEREXTENDEDDRIVINGCOUNT = 'co-driver-extended-driving-count',
    CODRIVERFIRSTSPLITDAILYREST = 'co-driver-first-split-daily-rest',
    CODRIVERID = 'co-driver-id',
    CODRIVERLOGINMANUALSTATUS = 'co-driver-login-manual-status',
    CODRIVERLONGLABORBREAK = 'co-driver-long-labor-break',
    CODRIVERMONTHLYDRIVERDUTY = 'co-driver-monthly-driver-duty',
    CODRIVERMONTHLYDUTY = 'co-driver-monthly-duty',
    CODRIVERNAME = 'co-driver-name',
    CODRIVERNIGHTLABOR = 'co-driver-night-labor',
    CODRIVEROPERATIONALWEEKDURATION = 'co-driver-operational-week-duration',
    CODRIVERREDUCEDDAILYRESTCOUNT = 'co-driver-reduced-daily-rest-count',
    CODRIVERSHIFTDURATION = 'co-driver-shift-duration',
    CODRIVERSHORTLABORBREAK = 'co-driver-short-labor-break',
    CODRIVERSUBACTIVITY = 'co-driver-sub-activity',
    CODRIVERTOTALWEEKLYRESTCOMPENSATION = 'co-driver-total-weekly-rest-compensation',
    CODRIVERWEEKLYDRIVING = 'co-driver-weekly-driving',
    CODRIVERWEEKLYDUTY = 'co-driver-weekly-duty',
    CODRIVERWEEKLYLABOR = 'co-driver-weekly-labor',
    CODRIVERWEEKLYRESTCOMPENSATION = 'co-driver-weekly-rest-compensation',
    CODRIVERWEEKLYRESTDUE = 'co-driver-weekly-rest-due',
    CONTINUOUSDRIVING = 'continuous-driving',
    CONTINUOUSLABORLONG = 'continuous-labor-long',
    CONTINUOUSLABORSHORT = 'continuous-labor-short',
    CONTINUOUSWORKAVAILABLE = 'continuous-work-available',
    COUNTRY = 'country',
    COUNTRYCODE = 'country-code',
    DAILYDRIVING = 'daily-driving',
    DAILYDUTY = 'daily-duty',
    DAILYRESTING = 'daily-resting',
    DEVICETYPE = 'device-type',
    DOUBLEMANNEDAVAILABLEBIWEEKLYDRIVINGTIME = 'double-manned-available-bi-weekly-driving-time',
    DOUBLEMANNEDAVAILABLEDAILYDRIVINGTIME = 'double-manned-available-daily-driving-time',
    DOUBLEMANNEDAVAILABLEWEEKLYDRIVINGTIME = 'double-manned-available-weekly-driving-time',
    DRIVEBREAK = 'drive-break',
    DRIVERACTIVITY = 'driver-activity',

    DRIVERID = 'driver-id',
    DRIVERNAME = 'driver-name',
    DRIVERSUBACTIVITY = 'driver-sub-activity',
    DURATION = 'duration',
    EBSMILEAGE = 'ebs-mileage',
    EBSTIMESTAMP = 'ebs-timestamp',
    EXTENDEDDRIVINGCOUNT = 'extended-driving-count',
    FIRSTSPLITDAILYREST = 'first-split-daily-rest',
    FUELLEVEL = 'fuel-level',
    HEADING = 'heading',
    IGNITIONSTATUS = 'ignition-status',
    INHIBITORSTATUS = 'inhibitor-status',
    LASTUPDATE = 'last-update',
    LATITUDE = 'latitude',
    LOGINMANUALSTATUS = 'login-manual-status',
    LONGITUDE = 'longitude',
    LONGLABORBREAK = 'long-labor-break',
    MONTHLYDRIVERDUTY = 'monthly-driver-duty',
    MONTHLYDUTY = 'monthly-duty',
    MSISDN = 'msisdn',
    NEARESTCITY = 'nearest-city',
    NIGHTLABOR = 'night-labor',
    ODOMETER = 'odometer',
    OPERATIONALWEEKDURATION = 'operational-week-duration',
    POSTALCODE = 'postal-code',
    REDUCEDDAILYRESTCOUNT = 'reduced-daily-rest-count',
    ROUTEDESTINATION = 'route-destination',
    ROUTEDRIVERHOURSETA = 'route-driver-hours-eta',
    ROUTEETA = 'route-eta',
    ROUTESTATUS = 'route-status',
    SHIFTDURATION = 'shift-duration',
    SHORTLABORBREAK = 'short-labor-break',

    SPEED = 'speed',
    TOTALWEEKLYRESTCOMPENSATION = 'total-weekly-rest-compensation',
    TRAILER = 'trailer',
    TRAILERCOMPARTMENT1LASTEVENT = 'trailer-compartment1-last-event',
    TRAILERCOMPARTMENT1RETURNTEMPERATURE = 'trailer-compartment1-return-temperature',
    TRAILERCOMPARTMENT1SETPOINTTEMPERATURE = 'trailer-compartment1-setpoint-temperature',
    TRAILERCOMPARTMENT1STATUS = 'trailer-compartment1-status',
    TRAILERCOMPARTMENT1STATUSMANUFACTURER = 'trailer-compartment1-status-manufacturer',

    TRAILERCOMPARTMENT1SUPPLYTEMPERATURE = 'trailer-compartment1-supply-temperature',
    TRAILERCOMPARTMENT1TEMPERATURESENSOR1 = 'trailer-compartment1-temperature-sensor1',
    TRAILERCOMPARTMENT1TEMPERATURESENSOR2 = 'trailer-compartment1-temperature-sensor2',
    TRAILERCOMPARTMENT1TEMPERATURESENSOR3 = 'trailer-compartment1-temperature-sensor3',
    TRAILERCOMPARTMENT1TEMPERATURESENSOR4 = 'trailer-compartment1-temperature-sensor4',
    TRAILERCOMPARTMENT2LASTEVENT = 'trailer-compartment2-last-event',
    TRAILERCOMPARTMENT2RETURNTEMPERATURE = 'trailer-compartment2-return-temperature',
    TRAILERCOMPARTMENT2SETPOINTTEMPERATURE = 'trailer-compartment2-setpoint-temperature',
    TRAILERCOMPARTMENT2STATUS = 'trailer-compartment2-status',
    TRAILERCOMPARTMENT2STATUSMANUFACTURER = 'trailer-compartment2-status-manufacturer',

    TRAILERCOMPARTMENT2SUPPLYTEMPERATURE = 'trailer-compartment2-supply-temperature',
    TRAILERCOMPARTMENT2TEMPERATURESENSOR1 = 'trailer-compartment2-temperature-sensor1',
    TRAILERCOMPARTMENT2TEMPERATURESENSOR2 = 'trailer-compartment2-temperature-sensor2',
    TRAILERCOMPARTMENT2TEMPERATURESENSOR3 = 'trailer-compartment2-temperature-sensor3',
    TRAILERCOMPARTMENT2TEMPERATURESENSOR4 = 'trailer-compartment2-temperature-sensor4',
    TRAILERCOMPARTMENT3LASTEVENT = 'trailer-compartment3-last-event',
    TRAILERCOMPARTMENT3RETURNTEMPERATURE = 'trailer-compartment3-return-temperature',
    TRAILERCOMPARTMENT3SETPOINTTEMPERATURE = 'trailer-compartment3-setpoint-temperature',
    TRAILERCOMPARTMENT3STATUS = 'trailer-compartment3-status',
    TRAILERCOMPARTMENT3STATUSMANUFACTURER = 'trailer-compartment3-status-manufacturer',

    TRAILERCOMPARTMENT3SUPPLYTEMPERATURE = 'trailer-compartment3-supply-temperature',
    TRAILERCOMPARTMENT3TEMPERATURESENSOR1 = 'trailer-compartment3-temperature-sensor1',
    TRAILERCOMPARTMENT3TEMPERATURESENSOR2 = 'trailer-compartment3-temperature-sensor2',
    TRAILERCOMPARTMENT3TEMPERATURESENSOR3 = 'trailer-compartment3-temperature-sensor3',
    TRAILERCOMPARTMENT3TEMPERATURESENSOR4 = 'trailer-compartment3-temperature-sensor4',
    TRAILERCOMPARTMENT4LASTEVENT = 'trailer-compartment4-last-event',
    TRAILERCOMPARTMENT4RETURNTEMPERATURE = 'trailer-compartment4-return-temperature',
    TRAILERCOMPARTMENT4SETPOINTTEMPERATURE = 'trailer-compartment4-setpoint-temperature',
    TRAILERCOMPARTMENT4STATUS = 'trailer-compartment4-status',
    TRAILERCOMPARTMENT4STATUSMANUFACTURER = 'trailer-compartment4-status-manufacturer',

    TRAILERCOMPARTMENT4SUPPLYTEMPERATURE = 'trailer-compartment4-supply-temperature',
    TRAILERCOMPARTMENT4TEMPERATURESENSOR1 = 'trailer-compartment4-temperature-sensor1',
    TRAILERCOMPARTMENT4TEMPERATURESENSOR2 = 'trailer-compartment4-temperature-sensor2',
    TRAILERCOMPARTMENT4TEMPERATURESENSOR3 = 'trailer-compartment4-temperature-sensor3',
    TRAILERCOMPARTMENT4TEMPERATURESENSOR4 = 'trailer-compartment4-temperature-sensor4',
    TRAILERDOOR1SENSOR = 'trailer-door1-sensor',
    TRAILERDOOR2SENSOR = 'trailer-door2-sensor',
    TRAILEREVENT = 'trailer-event',
    TRAILERID = 'trailer-number',
    TRAILERLASTEVENT = 'trailer-last-event',

    TRAILERREEFERSTATUS = 'trailer-reefer-status',
    TRAILERUNITID = 'trailer-unit-id',
    TRAILERWEIGHT = 'trailer-weight',
    VEHICLE = 'vehicle',

    WEEKLYDRIVING = 'weekly-driving',
    WEEKLYDUTY = 'weekly-duty',
    WEEKLYLABOR = 'weekly-labor',
    WEEKLYRESTCOMPENSATION = 'weekly-rest-compensation',

    WEEKLYRESTDUE = 'weekly-rest-due',
}
